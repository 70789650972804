import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/xu-ly-ho-so/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.BAN_GIAO_HS.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.BAN_GIAO_HS.DS,
            name: ROUTE_NAME.BAN_GIAO_HS.DS,
            component: () => import('@/modules/xu-ly-ho-so/views/ban-giao-ho-so/BanGiaoHoSo.vue'),
            meta: {
              code: ROUTE_NAME.BAN_GIAO_HS.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.BAN_GIAO_HS,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.BAN_GIAO_HO_SO.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.BAN_GIAO_HS.DS,
            component: () => import('@/modules/xu-ly-ho-so/views/ban-giao-ho-so/BanGiaoHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.BAN_GIAO_HS,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.BAN_GIAO_HO_SO.DANH_SACH],
            },
          },
          {
            path: `${ROUTE_NAME.BAN_GIAO_HS.SUA}/:id`,
            name: ROUTE_NAME.BAN_GIAO_HS.SUA,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/ThemMoiHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.BAN_GIAO_HS,
                },
                {
                  text: MENU_ROUTE_NAME.SUA,
                  active: true,
                },
              ],
            },
          },
          {
            path: ROUTE_NAME.BAN_GIAO_HS.CT,
            name: ROUTE_NAME.BAN_GIAO_HS.CT,
            component: () => import('@/modules/xu-ly-ho-so/views/ban-giao-ho-so/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                  to: ROUTE_NAME.BAN_GIAO_HS.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.BAN_GIAO_HO_SO.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.YEU_CAU_BS.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.YEU_CAU_BS.DS,
            name: ROUTE_NAME.YEU_CAU_BS.DS,
            component: () => import('@/modules/xu-ly-ho-so/views/yeu-cau-bo-sung/YeuCauBoSung.vue'),
            meta: {
              code: ROUTE_NAME.YEU_CAU_BS.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.YEU_CAU_BS,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.YEU_CAU_BO_SUNG.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.YEU_CAU_BS.DS,
            component: () => import('@/modules/xu-ly-ho-so/views/yeu-cau-bo-sung/YeuCauBoSung.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.YEU_CAU_BS,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.YEU_CAU_BO_SUNG.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.YEU_CAU_BS.CT,
            name: ROUTE_NAME.YEU_CAU_BS.CT,
            component: () => import('@/modules/xu-ly-ho-so/views/yeu-cau-bo-sung/ChiTietYeuCauBoSung.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                  to: ROUTE_NAME.YEU_CAU_BS.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.YEU_CAU_BO_SUNG.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.HS_DANG_XU_LY.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_DANG_XU_LY.DS,
            name: ROUTE_NAME.HS_DANG_XU_LY.DS,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/ho-so-dang-xu-ly/HoSoDangXuLy.vue'),
            meta: {
              code: ROUTE_NAME.HS_DANG_XU_LY.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_DANG_XU_LY,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_DANG_XU_LY.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.HS_DANG_XU_LY.CT,
            name: ROUTE_NAME.HS_DANG_XU_LY.CT,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/ho-so-dang-xu-ly/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_DANG_XU_LY,
                  to: ROUTE_NAME.HS_DANG_XU_LY.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_DANG_XU_LY.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.PHAN_XU_LY_HS.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.PHAN_XU_LY_HS.DS,
            name: ROUTE_NAME.PHAN_XU_LY_HS.DS,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/phan-xu-ly-ho-so/PhanXuLyHoSo.vue'),
            meta: {
              code: ROUTE_NAME.PHAN_XU_LY_HS.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.PHAN_XU_LY_HS,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.PHAN_XU_LY_HO_SO.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.PHAN_XU_LY_HS.CT,
            name: ROUTE_NAME.PHAN_XU_LY_HS.CT,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/phan-xu-ly-ho-so/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.PHAN_XU_LY_HS,
                  to: ROUTE_NAME.PHAN_XU_LY_HS.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.PHAN_XU_LY_HO_SO.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.DUYET_DE_XUAT.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.DUYET_DE_XUAT.DS,
            name: ROUTE_NAME.DUYET_DE_XUAT.DS,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/duyet-de-xuat/DuyetDeXuat.vue'),
            meta: {
              code: ROUTE_NAME.DUYET_DE_XUAT.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DUYET_DE_XUAT,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.DUYET_DE_XUAT.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.DUYET_DE_XUAT.CT,
            name: ROUTE_NAME.DUYET_DE_XUAT.CT,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/duyet-de-xuat/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DUYET_DE_XUAT,
                  to: ROUTE_NAME.DUYET_DE_XUAT.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.DUYET_DE_XUAT.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.DUYET_KY_HO_SO.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.DUYET_KY_HO_SO.DS,
            name: ROUTE_NAME.DUYET_KY_HO_SO.DS,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/duyet-ky-ho-so/DuyetKyHoSo.vue'),
            meta: {
              code: ROUTE_NAME.DUYET_KY_HO_SO.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DUYET_KY_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.DUYET_KY_HO_SO.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.DUYET_KY_HO_SO.CT,
            name: ROUTE_NAME.DUYET_KY_HO_SO.CT,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/duyet-ky-ho-so/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DUYET_KY_HO_SO,
                  to: ROUTE_NAME.DUYET_KY_HO_SO.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.DUYET_KY_HO_SO.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.GIA_HAN_HS.NAME,
        name: ROUTE_NAME.GIA_HAN_HS.NAME,
        component: () => import('@/modules/xu-ly-ho-so/components/pages/gia-han-ho-so/DuyetHoSoGiaHan.vue'),
        meta: {
          code: ROUTE_NAME.GIA_HAN_HS.NAME,
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.NAME,
            },
            {
              text: MENU_ROUTE_NAME.GIA_HAN_HS.NAME,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.DUYET_GIA_HAN_HO_SO.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.CAN_THU_PHI_LE_PHI.NAME,
        name: ROUTE_NAME.CAN_THU_PHI_LE_PHI.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.CAN_THU_PHI_LE_PHI.DS_THU_PHI,
            name: ROUTE_NAME.CAN_THU_PHI_LE_PHI.DS_THU_PHI,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/thu-phi-le-phi/ThuPhiLePhi.vue'),
            meta: {
              code: 'can-thu-phi-le-phi',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.CAN_THU_PHI_LE_PHI.NAME,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_CAN_THU_PHI.DANH_SACH],
            },
          },
          // {
          //   path: ROUTE_NAME.HS_TRUC_TIEP.CHI_TIET,
          //   name: ROUTE_NAME.HS_TRUC_TIEP.CHI_TIET,
          //   component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/ChiTietHoSo.vue'),
          //   meta: {
          //     breadcrumb: [
          //       {
          //         text: MENU_ROUTE_NAME.NAME,
          //       },
          //       {
          //         text: MENU_ROUTE_NAME.HS_TRUC_TIEP,
          //       },
          //       {
          //         text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
          //         active: true,
          //       },
          //     ],
          //   },
          // },
        ],
      },
      {
        path: ROUTE_NAME.CHO_THU_PHI_LE_PHI.NAME,
        name: ROUTE_NAME.CHO_THU_PHI_LE_PHI.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.CHO_THU_PHI_LE_PHI.DS_CHO_THU_PHI,
            name: ROUTE_NAME.CHO_THU_PHI_LE_PHI.DS_CHO_THU_PHI,
            component: () => import('@/modules/xu-ly-ho-so/components/pages/cho-thu-phi-le-phi/ChoThuPhiLePhi.vue'),
            meta: {
              code: 'cho-thu-phi-le-phi',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.CHO_THU_PHI_LE_PHI.NAME,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_CHO_THU_PHI.DANH_SACH],
            },
          },
        ],
      },
    ],
  },
]
