import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/modules/danh-muc/api/endpoints'

export function callApiDanhSachTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.TRANG_THAI.DANH_SACH,
    method: 'GET',
    params: payload,
  })
}

export function callApiThemMoiTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.TRANG_THAI.THEM_MOI,
    method: 'POST',
    data: payload,
  })
}

export function callApiSuaTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.TRANG_THAI.SUA,
    method: 'POST',
    data: payload,
  })
}

export function callApiXoaTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.TRANG_THAI.XOA,
    method: 'DELETE',
    data: payload,
  })
}
