import {
  callApiDanhSachBaoCao,
  callApiThemMoiBaoCao,
  callApiSuaBaoCao,
  callApiXoaBaoCao,
} from '@/modules/danh-muc/api/danh-muc-dung-chung/bao-cao'

const LayDanhSachBaoCao = (context, payload) => new Promise((resolve, reject) => {
  callApiDanhSachBaoCao(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // context.commit('SET_DANH_SACH_LAM_VIEC_TRONG_TUAN', res.data)
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const ThemMoiBaoCao = (context, payload) => new Promise((resolve, reject) => {
  callApiThemMoiBaoCao(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const SuaBaoCao = (context, payload) => new Promise((resolve, reject) => {
  callApiSuaBaoCao(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const XoaBaoCao = (context, payload) => new Promise((resolve, reject) => {
  callApiXoaBaoCao(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  LayDanhSachBaoCao,
  ThemMoiBaoCao,
  SuaBaoCao,
  XoaBaoCao,
}
