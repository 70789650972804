import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/api/endpoints'

export function callApiDanhSachLoaiTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.LOAI_TRANG_THAI.DANH_SACH,
    method: 'POST',
    data: payload,
  })
}

export function callApiThemMoiLoaiTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.LOAI_TRANG_THAI.THEM_MOI,
    method: 'POST',
    data: payload,
  })
}

export function callApiSuaLoaiTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.LOAI_TRANG_THAI.SUA,
    method: 'POST',
    data: payload,
  })
}

export function callApiXoaLoaiTrangThai(payload) {
  return HTTP({
    url: ENDPOINT.LOAI_TRANG_THAI.XOA,
    method: 'DELETE',
    data: payload,
  })
}
