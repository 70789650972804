import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/modules/danh-muc/api/endpoints'

export function callApiDanhSachNhomPhieu(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.NHOM_PHIEU.DANH_SACH,
    method: 'GET',
    params: payload,
  })
}

export function callApiThemMoiNhomPhieu(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.NHOM_PHIEU.THEM_MOI,
    method: 'POST',
    data: payload,
  })
}

export function callApiSuaNhomPhieu(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.NHOM_PHIEU.SUA,
    method: 'POST',
    data: payload,
  })
}

export function callApiXoaNhomPhieu(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.NHOM_PHIEU.XOA,
    method: 'DELETE',
    data: payload,
  })
}
