import { callApiDangNhap, callApiRefreshToken } from '@/api/common/index'
import {
  LOCAL_STORAGE_KEY,
  setValueToStorageByKey,
  getValueFromStorageByKey,
} from '@/utils/local-storage'
// eslint-disable-next-line import/named
import { getDateExpire } from '@/utils/role-permission-utils'
import _uniqBy from 'lodash/uniqBy'
import { HTTP } from '@/api/http-common'
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import route from '@/router'
import { ROUTER_PERMISSION } from '@/constants/constants'
import ENDPOINT from '@/api/endpoints'

const refreshTokens = (context, router) => {
  context.dispatch('autoRefresh', router)
}

const autoRefresh = (context, router) => {
  const { dispatch } = context
  const dateExpire = getDateExpire()
  const dateNow = new Date()
  const minus = (dateExpire - dateNow) / (60 * 1000)
  const timeOut = minus > 5 ? minus - 5 : minus
  setTimeout(() => {
    callApiRefreshToken({
      accessToken: getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
      refreshToken: getValueFromStorageByKey(LOCAL_STORAGE_KEY.REFRESH_TOKEN),
    }).then(res => {
      if (res.data.succeeded) {
        setValueToStorageByKey(
          LOCAL_STORAGE_KEY.ACCESS_TOKEN,
          res.data.data.accessToken,
        )
        dispatch('refreshTokens', router)
      }
    })
  }, timeOut * 60 * 1000)
}

const setIsLogin = (context, isLogin) => {
  context.commit('SET_IS_LOGIN', isLogin)
}

const handleLoginSuccess = (res, context) => {
  const permissions = _uniqBy(res.data.data.listPermissions, 'policy')
  if (permissions.length >= 0) {
    setValueToStorageByKey(
      LOCAL_STORAGE_KEY.ACCESS_TOKEN,
      res.data.data.accessToken,
    )
    setValueToStorageByKey(
      LOCAL_STORAGE_KEY.REFRESH_TOKEN,
      res.data.data.refreshToken,
    )
    setValueToStorageByKey(
      LOCAL_STORAGE_KEY.USER_INFO,
      res.data.data.userInformation,
    )
    setValueToStorageByKey(
      LOCAL_STORAGE_KEY.PERMISSIONS,
      permissions,
    )
    setValueToStorageByKey(
      LOCAL_STORAGE_KEY.IS_LOGIN,
      true,
    )
    context.commit('SET_PERMISSIONS', permissions)
    HTTP.defaults.headers = {
      Authorization: `Bearer ${getValueFromStorageByKey(
        LOCAL_STORAGE_KEY.ACCESS_TOKEN,
      )}`,
      'Access-Control-Allow-Origin': '*',
    }
    Vue.$toast.success('Đăng nhập thành công!')
    let breakLoop = false
    for (let i = 0; i < ROUTER_PERMISSION.length; i += 1) {
      if (breakLoop) {
        break
      }
      for (let j = 0; j < permissions.length; j += 1) {
        if (permissions[j].name === ROUTER_PERMISSION[i].permission) {
          route.push({
            path: ROUTER_PERMISSION[i].path,
          })
          breakLoop = true
          break
        }
      }
    }
  } else {
    Vue.$toast.warning('Tài khoản bạn vừa đăng nhập không có quyền. Hãy thử đăng nhập tài khoản khác!')
  }
}

const login = (context, payload) => new Promise((resolve, reject) => {
  callApiDangNhap(payload)
    .then(res => {
      if (res.data.statusCode === '200' && res.data.succeeded) {
        handleLoginSuccess(res, context)
        resolve(res.data)
      } else {
        reject(res.data)
        Vue.$toast.error('Tên đăng nhập hoặc mật khẩu sai!')
      }
    })
})

const loginUSB = (context, payload) => new Promise((resolve, reject) => {
  HTTP({
    url: ENDPOINT.AUTH.DANG_NHAP_USB,
    method: 'POST',
    data: payload,
  }).then(res => {
    if (res.data.succeeded) {
      handleLoginSuccess(res, context)
      resolve(res.data)
    } else {
      reject(res.data)
      Vue.$toast.error(res.data.message)
    }
  })
})

export default {
  setIsLogin,
  login,
  loginUSB,
  refreshTokens,
  autoRefresh,
}
