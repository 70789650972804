import thoiGianLamViecTrongTuan from './danh-muc-dung-chung/thoi-gian-lam-viec-trong-tuan/actions'
import loaiTrangThai from './danh-muc-dung-chung/loai-trang-thai/actions'
import trangThai from './danh-muc-dung-chung/trang-thai/actions'
import nhomPhieu from './danh-muc-dung-chung/nhom-phieu/actions'
import baoCao from './danh-muc-dung-chung/bao-cao/actions'

export default {
  ...thoiGianLamViecTrongTuan,
  ...loaiTrangThai,
  ...trangThai,
  ...nhomPhieu,
  ...baoCao,
}
