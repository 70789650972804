const SET_IS_LOGIN = (state, isLogin) => {
  state.isLogin = isLogin
}

const SET_PERMISSIONS = (state, permissions) => {
  state.permissions = permissions
}

const SET_DOC_NUMBER = (state, data) => {
  state.documentNumber = data
}

const SET_NAV_TREE = (state, data) => {
  state.navigations = data
}

export default {
  SET_IS_LOGIN,
  SET_PERMISSIONS,
  SET_DOC_NUMBER,
  SET_NAV_TREE,
}
