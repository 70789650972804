export default {
  HO_SO: '/HoSo',
  AUTH: {
    DANG_NHAP: '/authentication/authenticate',
    DANG_NHAP_USB: '/authentication/login-usb-token',
    REFRESH_TOKEN: '/auth/refresh-token',
  },
  TRANG_CHU: {
    TINH_HINH_XU_LY_HO_SO: 'dashboard/tinh-hinh-xu-ly-ho-so',
    GIAI_QUYET_HO_SO: 'dashboard/ty-le-giai-quyet-ho-so',
    HINH_THUC_NOP: 'dashboard/ty-le-ho-so-theo-hinh-thuc-nop',
    THU_PHI_LE_PHI: 'dashboard/ty-le-thu-phi-le-phi',
    XU_LY_HO_SO: 'dashboard/ty-le-xu-ly-ho-so',
  },
  MAU_PHIEU: {
    DS: 'mau-phieu/filter',
    THEM: 'mau-phieu/create',
    SUA: 'mau-phieu/update',
    XOA: 'mau-phieu/delete',
    COMBOBOX: 'mau-phieu/getlistcombobox',
    LAY_TRANG_THAI_ID: 'mau-phieu/laymauphieutheotrangthai',
  },
  DAN_TOC: {
    DS: 'dan-toc/filter',
    THEM: 'dan-toc/create',
    SUA: 'dan-toc/update',
    XOA: 'dan-toc/delete',
    COMBOBOX: 'dan-toc/getlistcombobox',
  },
  LINH_VUC: {
    DS: 'linh-vuc/filter',
    THEM: 'linh-vuc/create',
    SUA: 'linh-vuc/update',
    XOA: 'linh-vuc/delete',
    DONG_BO: 'linh-vuc/dong-bo-dvcqg',
    COMBOBOX: 'linh-vuc/getlistcombobox',
  },
  QUOC_TICH: {
    DS: 'quoc-tich/filter',
    THEM: 'quoc-tich/create',
    SUA: 'quoc-tich/update',
    XOA: 'quoc-tich/delete',
    COMBOBOX: 'quoc-tich/getlistcombobox',
  },
  LINH_VUC_NHOM_NGUOI_DUNG: {
    DS: 'linh-vuc-nhom-nguoi-dung/filter',
    THEM: 'linh-vuc-nhom-nguoi-dung/create',
    SUA: 'linh-vuc-nhom-nguoi-dung/update',
    XOA: 'linh-vuc-nhom-nguoi-dung/delete',
    DS_VAI_TRO: 'linh-vuc-nhom-nguoi-dung/vaitrochuacauhinh',
  },
  THU_TUC_HANH_CHINH_NHOM_NGUOI_DUNG: {
    DS: 'tthc-nhom-nguoi-dung/filter',
    THEM: 'tthc-nhom-nguoi-dung/create',
    SUA: 'tthc-nhom-nguoi-dung/update',
    XOA: 'tthc-nhom-nguoi-dung/delete',
    DS_VAI_TRO_CHUA_CAU_HINH: 'tthc-nhom-nguoi-dung/vaitrochuacauhinh',
  },
  BAO_CAO: {
    DS: 'bao-cao/filter',
    THEM: 'bao-cao/create',
    SUA: 'bao-cao/update',
    XOA: 'bao-cao/delete',
    COMBOBOX: 'bao-cao/getlistcombobox',
  },
  BAO_CAO_DON_VI: {
    DS: 'bao-cao-don-vi/filter',
    THEM: 'bao-cao-don-vi/create',
    SUA: 'bao-cao-don-vi/update',
    XOA: 'bao-cao-don-vi/delete',
    DS_BAO_CAO: 'bao-cao-don-vi/baocaochuacauhinh',
  },
  BAO_CAO_LINH_VUC: {
    DS: 'bao-cao-linh-vuc/filter',
    THEM: 'bao-cao-linh-vuc/create',
    SUA: 'bao-cao-linh-vuc/update',
    XOA: 'bao-cao-linh-vuc/delete',
    DS_BAO_CAO_CHUA_CAU_HINH: 'bao-cao-linh-vuc/baocaochuacauhinh',
  },
  CHUNG: {
    NHOM_NGUOI_DUNG: 'common/laynhomnguoidung',
    DON_VI: 'common/laydonvitheodonvigoc',
    NAV: 'bsd/navigations/all',
    NAV_TREE: 'bsd/navigations/alltree',
    NAV_LINK: 'bsd/navigations',
  },
  HANH_DONG: {
    DS: 'hanh-dong/filter',
    THEM: 'hanh-dong/create',
    SUA: 'hanh-dong/update',
    XOA: 'hanh-dong/delete',
    COMBOBOX: 'hanh-dong/getlistcombobox',
  },
  CAU_HOI: {
    DS: 'cau-hoi/filter',
    CHI_TIET: 'cau-hoi/detail',
    THEM: 'cau-hoi/create',
    SUA: 'cau-hoi/update',
    XOA: 'cau-hoi/delete',
    COMBOBOX: 'cau-hoi/getlistcombobox',
  },
  TIEU_CHI_DANH_GIA: {
    DS: 'tieu-chi-danh-gia/filter',
    THEM: 'tieu-chi-danh-gia/create',
    SUA: 'tieu-chi-danh-gia/update',
    XOA: 'tieu-chi-danh-gia/delete',
    COMBOBOX: 'tieu-chi-danh-gia/getlistcombobox',
  },
  PHUONG_THUC_DANH_GIA: {
    DS: 'phuong-thuc-danh-gia/filter',
    THEM: 'phuong-thuc-danh-gia/create',
    SUA: 'phuong-thuc-danh-gia/update',
    XOA: 'phuong-thuc-danh-gia/delete',
    COMBOBOX: 'phuong-thuc-danh-gia/getlistcombobox',
  },
  GIAY_TO: {
    DS: 'giay-to/filter',
    THEM: 'giay-to/create',
    SUA: 'giay-to/update',
    XOA: 'giay-to/delete',
    COMBOBOX: 'giay-to/getlistcombobox',
  },
  CONG_THANH_TOAN: {
    DS: 'cong-thanh-toan/filter',
    THEM: 'cong-thanh-toan/create',
    SUA: 'cong-thanh-toan/update',
    XOA: 'cong-thanh-toan/delete',
    COMBOBOX: 'cong-thanh-toan/getlistcombobox',
  },
  NHAT_KY_HE_THONG: {
    DS: 'lich-su-thao-tac/filter',
  },
  THU_TUC_HANH_CHINH: {
    DS: 'thu-tuc-hanh-chinh/filter',
    THEM: 'thu-tuc-hanh-chinh/create',
    SUA: 'thu-tuc-hanh-chinh/update',
    XOA: 'thu-tuc-hanh-chinh/delete',
    DONG_BO: 'thu-tuc-hanh-chinh/dong-bo-dvcqg',
    CHI_TIET: 'thu-tuc-hanh-chinh/detail',
    CAP_NHAT_GIAY_TO: 'thu-tuc-hanh-chinh/capnhatgiaytomauhoso',
    CHI_TIET_GIAY_TO: 'thu-tuc-hanh-chinh/mauhosocombobox',
    COMBOBOX: 'thu-tuc-hanh-chinh/getlistcombobox',
    DS_MAU_HO_SO_GT: 'thu-tuc-hanh-chinh/mauhosogiayto',
    MAU_HO_SO: 'thu-tuc-hanh-chinh/mauhosocombobox',
    LAY_TO_KHAI: 'thu-tuc-hanh-chinh/lay-bieu-mau',
    XOA_GT_MHS: 'thu-tuc-hanh-chinh/deletemauhosogiayto',
  },
  PHAN_QUYEN_MAU_PHIEU_TRANG_THAI: {
    DS: 'mau-phieu-trang-thai/filter',
    THEM: 'mau-phieu-trang-thai/create',
    SUA: 'mau-phieu-trang-thai/update',
    XOA: 'mau-phieu-trang-thai/delete',
  },
  UPLOAD_FILE: {
    MULTI: 'upload/multi',
    DOWNLOAD: 'upload/download',
    DOWNLOAD_DVC: 'upload/download-file-dvc',
  },
  HUONG_DAN_SU_DUNG: {
    DS: 'hdsd/filter',
    THEM: 'hdsd/create',
    SUA: 'hdsd/update',
    XOA: 'hdsd/delete',
    COMBOBOX: 'hdsd/getlistcombobox',
  },
  WORKFLOW_SCHEME_ATTRIBUTE: {
    DS: 'wf/scheme/attributes/filter',
    THEM: 'wf/scheme/attributes',
    SUA: 'wf/scheme/attributes',
    XOA: 'wf/scheme/attributes',
    ALL: 'wf/scheme/attributes/all',
    CHI_TIET: 'wf/scheme/attributes',
  },
  WORKFLOW_SCHEME_DESIGNERAPI: {
    API_URL: 'wf/scheme/designerapi',
  },
  WORKFLOW_SCHEME_VERSION: {
    THEM: 'wf/scheme/versions',
    SUA: 'wf/scheme/versions',
    ALL: 'wf/scheme/versions/all',
  },
  WORKFLOW_CUSTOM: {
    DANH_SACH_XU_LY: 'wf-custom/getavailablecommands',
    DANH_SACH_NGUOI_XU_LY: 'wf-custom/nextuser',
    THUC_THI: 'wf-custom/executecommand',
    TIEN_TRINH_XU_LY: 'wf-custom/getdocumenthistoryfortracuu',
    XU_LY_NHIEU_HO_SO: 'wf-custom/multiapproval',
  },
  WORKFLOW_GLOBAL_PARAMETERS: {
    THEM: 'wf/globalparameters/createbusinessflow',
    SUA: 'wf/globalparameters/updatebusinessflow',
    TTHC_DS: 'wf/globalparameters/tthcchuacauhinh',
    DS: 'wf/globalparameters/getfilterv2',
    XOA: 'wf/globalparameters',
  },
  USER: {
    NAME: '/users',
    LAY_CHI_TIET_NGUOI_DUNG: '/users',
    NGUOI_DUNG_GOM_QUYEN: '/users/search',
    DANH_SACH_VAI_TRO_THEO_NGUOI_DUNG: '/users',
    DANH_SACH_VAI_TRO_KHONG_THUOC_NGUOI_DUNG: '/users',
    DANH_SACH_QUYEN_THEO_NGUOI_DUNG: '/users',
    DANH_SACH_QUYEN_KHONG_THUOC_NGUOI_DUNG: '/users',
    CAP_NHAT_VAI_TRO_THEO_NGUOI_DUNG: '/users',
    CAP_NHAT_QUYEN_THEO_NGUOI_DUNG: '/users',
    CAP_NHAT_EMAIL_NGUOI_DUNG: '/users',
  },
  PERMISSION: {
    DANH_SACH_TAT_CA_QUYEN: '/permissions/search',
  },
  ROLE: {
    DANH_SACH_QUYEN_THEO_VAI_TRO: '/roles',
    DANH_SACH_QUYEN_KHONG_THUOC_VAI_TRO: '/roles',
    CAP_NHAT_QUYEN_TRONG_VAI_TRO: '/roles',
    NGUOI_DUNG_THEO_VAI_TRO: '/roles',
    NGUOI_DUNG_KHONG_THUOC_VAI_TRO: '/roles',
    THEM_NGUOI_DUNG_TRONG_VAI_TRO: '/roles',
    XOA_NGUOI_DUNG_TRONG_VAI_TRO: '/roles',
    CAP_NHAT_THONG_TIN_VAI_TRO: '/roles',
    DANH_SACH_VAI_TRO: '/roles/search',
    XOA_VAI_TRO: '/roles/',
    TAO_VAI_TRO: '/roles',
    CHI_TIET_VAI_TRO: '/roles',
  },
  THOI_GIAN_LAM_VIEC_TRONG_TUAN: {
    DANH_SACH: '/ngay-lam-viec/filter',
    THEM_MOI: '/ngay-lam-viec/createorupdate',
    SUA: '/ngay-lam-viec/createorupdate',
    XOA: '/ngay-lam-viec/delete',
    THEM_NGAY_NGHI: '/ngay-lam-viec/capnhatngaynghi',
  },
  LOAI_TRANG_THAI: {
    DANH_SACH: '/loai-trang-thai/filter',
    THEM_MOI: '/loai-trang-thai/create',
    SUA: '/loai-trang-thai/update',
    XOA: '/loai-trang-thai/delete',
    COMBOBOX: 'loai-trang-thai/getlistcombobox',
  },
  TRANG_THAI: {
    DANH_SACH: '/trang-thai/filter',
    THEM_MOI: '/trang-thai/create',
    SUA: '/trang-thai/update',
    XOA: '/trang-thai/delete',
    COMBOBOX: 'trang-thai/getlistcombobox',
  },
  DONG_BO: {
    DANH_SACH: '/dossiers',
    DANH_SACH_DVCQG: '/ho-so/dong-bo-dvcqg/tra-cu-ho-so',
    CHI_TIET: '/dossiers/detail',
    DONG_BO_DVCQG: '/ho-so/dong-bo-dvcqg/tao-moi',
  },
  NHOM_PHIEU: {
    DANH_SACH: '/nhom-phieu/filter',
    THEM_MOI: '/nhom-phieu/create',
    SUA: '/nhom-phieu/update',
    XOA: '/nhom-phieu/delete',
    COMBOBOX: 'nhom-phieu/getlistcombobox',
  },
  NHOM_TIEU_CHI: {
    DANH_SACH: '/nhom-tieu-chi/filter',
    THEM_MOI: '/nhom-tieu-chi/create',
    SUA: '/nhom-tieu-chi/update',
    XOA: '/nhom-tieu-chi/delete',
    COMBOBOX: 'nhom-tieu-chi/getlistcombobox',
  },
  THUOC_TINH_TIEU_CHI: {
    DANH_SACH: '/thuoc-tinh-tieu-chi-danh-gia/filter',
    THEM_MOI: '/thuoc-tinh-tieu-chi-danh-gia/create',
    SUA: '/thuoc-tinh-tieu-chi-danh-gia/update',
    XOA: '/thuoc-tinh-tieu-chi-danh-gia/delete',
  },
  BIEU_MAU: {
    URL: () => '/bsd/form-masters',
    filterColumns: () => '/bsd/form-masters/filter',
    capNhat: id => `/bsd/form-masters/${id}`,
    bieuMauCon: () => '/bsd/form-masters/all',
    getColumnTable: id => `/bsd/form-masters/${id}/column`,
    cloneBieuMau: id => `/bsd/form-masters/${id}/clone`,
    templateBieuMau: id => `/bsd/form-masters/${id}/template`,
    COMBOBOX: 'bsd/form-masters/combobox',
  },
  TIEP_NHAN_HO_SO_TRUC_TIEP: {
    DANH_SACH: 'ho-so/filter',
    THEM_MOI: 'ho-so/create',
    SUA: 'ho-so/update',
    XOA: 'ho-so/delete',
    CHI_TIET: 'ho-so/detail',
    TIEP_NHAN: 'ho-so/tiepnhanhoso',
    LAY_HAN_TRA_KET_QUA: 'ho-so/layngayhentraketqua',
    TU_CHOI: 'ho-so/tuchoitiepnhanhoso',
    DANH_SACH_CHO_TIEP_NHAN: 'ho-so/danhsachhosochotiepnhan',
    CHI_TIET_CHO_TIEP_NHAN: 'ho-so/chitietchotiepnhan',
    BO_SUNG: 'ho-so/yeucaubosunghosotructuyen',
    CHI_TIET_BIEU_MAU: 'ho-so/chi-tiet-bieu-mau-ho-so',
    TINH_NGAY_TRA_KG: 'ho-so/tinhngayhentraketqua',
    KIEM_TRA_CONG_DAN: '/ho-so/chu-ho-so/csdl-dancu',
    GET_DANH_SACH_FILE: '/ho-so/giayto-dvcqg',
    DOWNLOAD_FILE: 'upload/download-giayto-dvcqg',
  },
  TIEP_NHAN_HO_SO_BO_SUNG: {
    DANH_SACH_HO_SO_BO_SUNG: 'ho-so/filter',
    THEM_MOI: 'ho-so/create',
    SUA: 'ho-so/update',
    XOA: 'ho-so/delete',
    CHI_TIET: 'ho-so/detail',
    TIEP_NHAN: 'ho-so/tiepnhanhoso',
    CHO_TIEP_NHAN: 'ho-so/danhsachhosochotiepnhan',
    TU_CHOI: 'ho-so/tuchoitiepnhanhoso',
    THUOC_TINH: 'ho-so/lay-thuoc-tinh-chi-tiet',
  },
  HO_SO_THU_PHI_LE_PHI: {
    YC_THU_PHI_HO_SO: 'ho-so/yeu-cau-thu-phi-ho-so',
    THU_PHI_HO_SO: 'ho-so/thu-phi-ho-so',
    CAP_NHAT_LE_PHI_HO_SO: 'ho-so/cap-nhat-le-phi-ho-so',
  },
  TRA_CUU: {
    TRA_CUU_HO_SO: 'ho-so/tra-cuu-ho-so',
  },
  COMMON: {
    LAY_DON_VI: 'common/laydonvi',
    LAY_SO_LUONG_HO_SO: 'common/so-luong-ho-so-navigation',
  },
  CAU_HINH_THAM_SO: {
    DS: 'tham-so-he-thong/filter',
    SUA: 'tham-so-he-thong/update',
  },
  BAO_CAO_THONG_KE: {
    LAY_DANH_SACH: 'baocao/6a',
    EXPORT_BAO_CAO: 'baocao/6a-export-excel',
    EXPORT_HO_SO: 'baocao/ho-so-export-excel',
    DANH_SACH_HO_SO: 'baocao/danh-sach-ho-so',
    NHAP_LIEU_THONG_KE: 'nhap-lieu-bao-cao/luu-th-thgq-tthc',
    CHI_TIET_NHAP_LIEU_THONG_KE: 'nhap-lieu-bao-cao/th-thgq-tthc',
    EXPORT_NHAP_LIEU_BAO_CAO: 'nhap-lieu-bao-cao/th-thgq-tthc-export-excel',
  },
  THONG_BAO_HE_THONG: {
    LAY_DANH_SACH: 'thong-bao/viewallnoti',
    PHAN_TRANG: 'thong-bao/getlistnotification',
    SUA: 'thong-bao/updatestatusnotification',
    XOA: 'thong-bao/delete',
  },
  KY_SO: {
    LAY_DANH_SACH: '/ky-so/filter',
    THEM_MOI: 'ky-so/create',
    CAP_NHAT_TRANG_THAI: 'ky-so/cap-nhat-trang-thai',
  },
}
