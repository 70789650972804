import Vue from 'vue'
import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/api/endpoints'

export function callApiDangNhap(payload) {
  return HTTP({
    url: ENDPOINT.AUTH.DANG_NHAP,
    method: 'POST',
    data: payload,
  })
}

export function callApiRefreshToken(payload) {
  return HTTP({
    url: ENDPOINT.AUTH.REFRESH_TOKEN,
    method: 'POST',
    data: payload,
  })
}

const commom = (url, method, data, toat = true) => new Promise((resolve, reject) => HTTP({
  url,
  method,
  data,
}).then(res => {
  if (toat) {
    if (res.status === 200 && res.data.succeeded) {
      Vue.$toast.success(res.data.message)
    } else {
      Vue.$toast.error(res.data.message)
    }
  }
  resolve(res)
}).catch(err => {
  reject(err)
}))

Vue.prototype.$axios = {
  get(url, params = null) {
    return new Promise((resolve, reject) => HTTP({
      url,
      method: 'GET',
      params,
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    }))
  },
  post(url, data, toat) {
    return commom(url, 'POST', data, toat)
  },
  put(url, data, toat) {
    return commom(url, 'PUT', data, toat)
  },
  delete(url, data, toat) {
    return commom(url, 'DELETE', data, toat)
  },
}
