// eslint-disable-next-line import/no-cycle
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  danhSachLoaiTrangThai: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
