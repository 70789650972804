<template>
  <div id="loading-api">
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style scoped>
#loading-api {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.13);
}
#loading-api.show {
  opacity: 1;
  visibility: visible;
}
</style>
