// eslint-disable-next-line import/prefer-default-export
import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/mot-cua/constants/constants'
import { MENU_ROUTE_NAME as MENU_ROUTE_NAME1 } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.MOT_CUA.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.MOT_CUA.PQ_LINH_VUC,
        name: ROUTE_NAME.MOT_CUA.PQ_LINH_VUC,
        component: () => import('@/modules/mot-cua/views/PhanQuyenLinhVuc.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.MOT_CUA.NAME,
            },
            {
              text: MENU_ROUTE_NAME.MOT_CUA.PQ_LINH_VUC,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_LV,
            name: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_LV,
            component: () => import('@/modules/mot-cua/views/bao-cao-thong-ke/PhanQuyenBaoCaoLinhVuc.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.MOT_CUA.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_LV,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_LINH_VUC.DANH_SACH],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_DV,
            name: ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_DV,
            component: () => import('@/modules/mot-cua/views/bao-cao-thong-ke/PhanQuyenBaoCaoDonVi.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.MOT_CUA.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.MOT_CUA.BAO_CAO_THONG_KE.PQ_BC_DV,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.DANH_SACH],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.MOT_CUA.HANH_DONG,
        name: ROUTE_NAME.MOT_CUA.HANH_DONG,
        component: () => import('@/modules/mot-cua/views/HanhDong.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME1.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.MOT_CUA.HANH_DONG,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.MOT_CUA.NHAT_KY_HE_THONG,
        name: ROUTE_NAME.MOT_CUA.NHAT_KY_HE_THONG,
        component: () => import('@/modules/mot-cua/views/NhatKyHeThong.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.MOT_CUA.NHAT_KY_HE_THONG,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.NHAT_KY_HE_THONG.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.MOT_CUA.HUONG_DAN_SU_DUNG,
        name: ROUTE_NAME.MOT_CUA.HUONG_DAN_SU_DUNG,
        component: () => import('@/modules/mot-cua/views/HuongDanSuDung.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME1.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.MOT_CUA.HUONG_DAN_SU_DUNG,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.MOT_CUA.PQ_TTHC_NND,
        name: ROUTE_NAME.MOT_CUA.PQ_TTHC_NND,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/PhanQuyenTTHCNhomNguoiDung.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.MOT_CUA.NAME,
            },
            {
              text: MENU_ROUTE_NAME.MOT_CUA.PQ_TTHC_NND,
              active: true,
            },
          ],
        },
      },
    ],
  },
]
