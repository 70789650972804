import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.HS_TRUC_TIEP.NAME,
        name: ROUTE_NAME.HS_TRUC_TIEP.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH,
            name: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/DanhSachHoSo.vue'),
            meta: {
              code: 'tiep-nhan-ho-so-truc-tiep',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TIEP,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HSTNTRUC_TIEP.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.THEM_MOI,
            name: ROUTE_NAME.THEM_MOI,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/ThemMoiHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TIEP,
                  to: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH,
                },
                {
                  text: MENU_ROUTE_NAME.THEM_MOI,
                  active: true,
                },
              ],
            },
          },
          {
            path: ROUTE_NAME.HS_TRUC_TIEP.CAP_NHAT,
            name: ROUTE_NAME.HS_TRUC_TIEP.CAP_NHAT,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/ThemMoiHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TIEP,
                  to: '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep',
                },
                {
                  text: MENU_ROUTE_NAME.CAP_NHAT,
                  active: true,
                },
              ],
            },
          },
          {
            path: ROUTE_NAME.HS_TRUC_TIEP.CHI_TIET,
            name: ROUTE_NAME.HS_TRUC_TIEP.CHI_TIET,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/ChiTietHoSo.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TIEP,
                  to: ROUTE_NAME.HS_TRUC_TIEP.DANH_SACH,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.HS_TRUC_TUYEN.NAME,
        name: ROUTE_NAME.HS_TRUC_TUYEN.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_TRUC_TUYEN.DS,
            name: ROUTE_NAME.HS_TRUC_TUYEN.DS,
            component: () => import('@/modules/tiep-nhan-ho-so/views/tiep-nhan-truc-tuyen/TiepNhanTrucTuyen.vue'),
            meta: {
              code: 'tiep-nhan-ho-so-truc-tuyen',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TUYEN,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH, PERMISSION_NAME.HSTNTRUC_TUYEN.BO_SUNG_DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.HS_TRUC_TUYEN.DS,
            component: () => import('@/modules/tiep-nhan-ho-so/views/tiep-nhan-truc-tuyen/TiepNhanTrucTuyen.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TUYEN,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH, PERMISSION_NAME.HSTNTRUC_TUYEN.BO_SUNG_DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.HS_TRUC_TUYEN.CT,
            name: ROUTE_NAME.HS_TRUC_TUYEN.CT,
            component: () => import('@/modules/tiep-nhan-ho-so/views/tiep-nhan-truc-tuyen/ChiTietTiepNhan.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                  name: ['tiep-nhan-ho-so-truc-tuyen'],
                },
                {
                  text: MENU_ROUTE_NAME.HS_TRUC_TUYEN,
                  to: ROUTE_NAME.HS_TRUC_TUYEN.DS,
                  name: ['tiep-nhan-ho-so-truc-tuyen'],
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
            },
          },
        ],
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.NAME,
            },
            {
              text: MENU_ROUTE_NAME.HS_TRUC_TUYEN,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.TN_BO_SUNG.NAME,
        name: ROUTE_NAME.TN_BO_SUNG.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.TN_BO_SUNG.DS_BS,
            name: ROUTE_NAME.TN_BO_SUNG.DS_BS,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/TiepNhanHoSoBoSung/TiepNhanHoSoBoSung.vue'),
            meta: {
              code: 'tiep-nhan-ho-so-bo-sung',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.TN_BO_SUNG,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.TIEP_NHAN_BO_SUNG.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.TN_BO_SUNG.DS_BS,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/TiepNhanHoSoBoSung/TiepNhanHoSoBoSung.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.TN_BO_SUNG,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.TIEP_NHAN_BO_SUNG.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.TN_BO_SUNG.CT_BS,
            name: ROUTE_NAME.TN_BO_SUNG.CT_BS,
            component: () => import('@/modules/tiep-nhan-ho-so/components/pages/TiepNhanHoSoBoSung/ChiTietTiepNhan.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.TN_BO_SUNG,
                  to: ROUTE_NAME.TN_BO_SUNG.DS_BS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
            },
          },
        ],
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.NAME,
            },
            {
              text: MENU_ROUTE_NAME.TN_BO_SUNG,
              active: true,
            },
          ],
        },
      },
    ],
  },
]
