// eslint-disable-next-line import/named
import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/quan-tri-he-thong/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export const router = [
  {
    path: ROUTE_NAME.NAME + ROUTE_NAME.FORM.DANH_SACH,
    name: ROUTE_NAME.FORM.DANH_SACH,
    component: () => import('@/modules/quan-tri-he-thong/views/TrangDanhSachForm.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.FORM.DANH_SACH,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.FORM.DANH_SACH,
          active: true,
        },
      ],
      permissionName: [PERMISSION_NAME.BIEU_MAU.DANH_SACH],
    },
  },
  {
    path: ROUTE_NAME.NAME + ROUTE_NAME.FORM.THEM_MOI,
    name: ROUTE_NAME.FORM.THEM_MOI,
    component: () => import('@/modules/quan-tri-he-thong/views/TrangTaoForm.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.FORM.THEM_MOI,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.FORM.DANH_SACH,
          to: { name: ROUTE_NAME.FORM.DANH_SACH },
        },
        {
          text: MENU_ROUTE_NAME.FORM.THEM_MOI,
          active: true,
        },
      ],
    },
  },
  {
    path: `${ROUTE_NAME.NAME + ROUTE_NAME.FORM.SUA}/:id`,
    name: ROUTE_NAME.FORM.SUA,
    component: () => import('@/modules/quan-tri-he-thong/views/TrangTaoForm.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.FORM.SUA,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.FORM.DANH_SACH,
          to: { name: ROUTE_NAME.FORM.DANH_SACH },
        },
        {
          text: MENU_ROUTE_NAME.FORM.SUA,
          active: true,
        },
      ],
    },
  },
  {
    path: `${ROUTE_NAME.NAME + ROUTE_NAME.FORM.CHI_TIET}/:id`,
    name: ROUTE_NAME.FORM.CHI_TIET,
    component: () => import('@/modules/quan-tri-he-thong/views/TrangTaoForm.vue'),
    meta: {
      detail: true,
      pageTitle: MENU_ROUTE_NAME.FORM.CHI_TIET,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.FORM.DANH_SACH,
          to: { name: ROUTE_NAME.FORM.DANH_SACH },
        },
        {
          text: MENU_ROUTE_NAME.FORM.CHI_TIET,
          active: true,
        },
      ],
    },
  },
  {
    path: `${ROUTE_NAME.NAME}/${ROUTE_NAME.NAVIGATION}`,
    name: ROUTE_NAME.NAVIGATION,
    component: () => import('@/modules/quan-tri-he-thong/components/pages/navigations/QuanLyDieuHuong.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.NAVIGATION,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.NAVIGATION,
          active: true,
        },
      ],
      permissionName: [PERMISSION_NAME.BIEU_MAU.DANH_SACH],
    },
  },
  {
    path: `${ROUTE_NAME.NAME}/${ROUTE_NAME.LOGS_ASYNC}`,
    name: ROUTE_NAME.LOGS_ASYNC,
    component: () => import('@/modules/quan-tri-he-thong/components/pages/DanhSachDongBo.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.LOG,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.LOG,
          active: true,
        },
      ],
    },
  },
  {
    path: `${ROUTE_NAME.NAME}/${ROUTE_NAME.LOGS_DVCQG}`,
    name: ROUTE_NAME.LOGS_DVCQG,
    component: () => import('@/modules/quan-tri-he-thong/components/pages/TraCuuDVCQG.vue'),
    meta: {
      pageTitle: MENU_ROUTE_NAME.LOG_DVCQG,
      breadcrumb: [
        {
          text: MENU_ROUTE_NAME.NAME,
        },
        {
          text: MENU_ROUTE_NAME.LOG_DVCQG,
          active: true,
        },
      ],
    },
  },
]
