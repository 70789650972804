export const ROUTE_NAME = {
  NAME: '/tra-cuu/',
  TC_HO_SO: {
    NAME: 'tra-cuu-ho-so',
    DS: 'tra-cuu-ho-so',
  },
}
export const STATUS_TIEP_NHAN = [
  {
    id: 0,
    label: 'Trực tiếp',
  },
  {
    id: 1,
    label: 'Trực tuyến',
  },
]
export const MENU_ROUTE_NAME = {
  NAME: 'Tra cứu',
  DS: 'Tra cứu hồ sơ',
}
