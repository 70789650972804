/* eslint-disable radix */
import Vue from 'vue'
import moment from 'moment'

const getDropdownMenu = el => {
  const item = el.querySelector('.vs__dropdown-menu') || el.querySelector('.vue-treeselect__menu')
  if (item) {
    const rect = el.getBoundingClientRect()
    item.style.position = 'fixed'
    item.style.maxWidth = `${el.offsetWidth}px`
    item.style.top = `${rect.bottom}px`
    item.style.left = `${rect.left}px`
    item.style.fontWeight = 'normal'
  }
  const level = el.getElementsByClassName('vue-treeselect__indent-level-1')
  level.forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.style.margin = '0 20px'
  })
  if (!item) {
    window.removeEventListener('scroll', () => {
      getDropdownMenu(el)
    })
  }
}

Vue.directive('format-v-select', {
  update: el => {
    el.addEventListener('click', () => {
      setTimeout(() => {
        const item = el.querySelector('.vs__dropdown-menu') || el.querySelector('.vue-treeselect__menu')
        if (item) {
          const rect = el.getBoundingClientRect()
          item.style.position = 'fixed'
          item.style.maxWidth = `${el.offsetWidth}px`
          item.style.top = `${rect.top + 32}px`
          item.style.left = `${rect.left}px`
          item.style.fontWeight = 'normal'
        }
        const level = el.getElementsByClassName('vue-treeselect__indent-level-1')
        level.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.style.margin = '0 20px'
        })
        window.addEventListener('scroll', () => {
          getDropdownMenu(el)
        })
      }, 0)
    })
  },
})

Vue.directive('set-height-text', {
  update: el => {
    if (el) {
      setTimeout(() => {
        const item = el
        item.style.height = 'auto'
        item.style.height = `${el.scrollHeight}px`
      }, 0)
    }
    el.addEventListener('input', () => {
      setTimeout(() => {
        const item = el
        item.style.height = 'auto'
        item.style.height = `${el.scrollHeight}px`
      }, 0)
    })
  },
})

Vue.directive('format-positive-integers', {
  update: el => {
    el.addEventListener('keydown', e => {
      // bỏ nhập ký tự - và key down khi gia tri nho hon 0
      if (e.keyCode === 109 || e.keyCode === 189 || ((e.keyCode === 40 || e.keyCode === 229) && e.target.value <= 0)) {
        e.preventDefault()
      }
    })
  },
})

Vue.directive('format-number-null', {
  update: el => {
    el.addEventListener('change', e => {
      if (e.target.value.length <= 0) {
        e.target.value = 0
      }
    })
  },
})

Vue.directive('is-int', {
  update: el => {
    el.addEventListener('change', e => {
      const num = e.target.value
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(num) && parseInt(Number(num)) === num && !isNaN(parseInt(num, 10))) {
        e.target.value = 0
      }
    })
  },
})

Vue.directive('trim-input', {
  update: el => {
    el.addEventListener('change', e => {
      // eslint-disable-next-line no-restricted-globals
      if (e.target.value) {
        e.target.value = e.target.value.replace(/(^\s*)|(\s*$)/gi, '').replace(/[ ]{2,}/gi, ' ').replace(/\n +/, '\n')
      }
    })
  },
})

Vue.directive('format-date', {
  bind: el => {
    el.querySelector('input').setAttribute('valueFormat', el.querySelector('input').getAttribute('placeholder'))
  },
  update: el => {
    el.addEventListener('change', e => {
      if (['date', 'datetime-local'].includes(e.target.type)) {
        if (e.target.value) {
          const attr = e.target.getAttribute('format')
          e.target.setAttribute('valueFormat', moment(e.target.value, 'YYYY-MM-DD HH:mm A').format(attr || 'DD/MM/YYYY HH:mm A'))
        } else {
          e.target.setAttribute('valueFormat', e.target.getAttribute('placeholder'))
        }
      }
    })
  },
})
