<template>
  <ckeditor
    v-model="editorData"
    :editor="editor"
    :readonly="getDisabled"
    :disabled="getDisabled"
    :config="editorConfig"
    @ready="onReady"
    @input="onEditorInput"
  />
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import '@/utils/vi'

export default {
  name: 'FormCKEditor',
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.$parent.$attrs.item.value || '',
      editorConfig: {
        language: 'vi',
        plugins: [
          EssentialsPlugin,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties'],
        },
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'code',
            'undo',
            'redo',
          ],
        },
      },
    }
  },
  computed: {
    getDisabled() {
      return this.$parent.$attrs.disabled || false
    },
  },
  methods: {
    onEditorInput() {
      this.$emit('control-value', this.editorData)
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement(),
        )
    },
  },
}
</script>

<style scoped>

</style>
