export const ROUTE_NAME = {
  NAME: '/tra-ket-qua/',
  HS_GIAI_QUYET: {
    NAME: 'hs-giai-quyet',
    DS_HS: 'hs-giai-quyet',
    CT_HS: 'hs-gq-chi-tiet',
  },
  HS_KHONG_GIAI_QUYET: {
    NAME: 'hs-khong-giai-quyet',
    DS: 'hs-khong-giai-quyet',
    CT: 'ct-ds-khong-giai-quyet',
  },
}

export const MENU_ROUTE_NAME = {
  NAME: 'Trả kết quả hồ sơ',
  HS_GIAI_QUYET: 'Hồ sơ giải quyết',
  HS_KHONG_GIAI_QUYET: 'Hồ sơ từ chối giải quyết',
  CHI_TIET_HO_SO: 'Chi tiết hồ sơ',
}
