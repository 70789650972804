import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  LOCAL_STORAGE_KEY,
  getValueFromStorageByKey, setValueToStorageByKey,
} from '@/utils/local-storage'
// eslint-disable-next-line import/no-cycle
import { hasPermission } from '@/utils/permission-utils'
import { ROUTER_PERMISSION } from '@/constants/constants'
import axios from 'axios'
import _uniqBy from 'lodash/uniqBy'
import trangChu from './trang-chu'
import pages from './pages'
import quanTriHeThong from './quan-tri-he-thong'
import quanLyQuyTrinh from './quan-ly-quy-trinh'
import tnhs from '../modules/tiep-nhan-ho-so/router/index'
import tracuu from '../modules/tra-cuu/router/index'
import nkq from '../modules/nhan-ket-qua/router/index'
import tkqhs from '../modules/tra-ket-qua/router/index'
import qldm from '../modules/danh-muc/router/index'
import xlhs from '../modules/xu-ly-ho-so/router/index'
import mc from '../modules/mot-cua/router/index'
import bctk from '../modules/bao-cao-thong-ke/router/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'trang-chu' } },
    {
      path: '/khong-quyen',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    },
    {
      path: '/thong-tin-ca-nhan',
      component: () => import('@/views/pages/account-setting/AccountInfo.vue'),
    },
    {
      path: '/ho-so/chi-tiet',
      component: () => import('@/components/ChiTietHoSoCommon.vue'),
      meta: {
        code: 'tiep-nhan-ho-so-truc-tiep',
        isCheckName: true,
        breadcrumb: [
          {
            text: 'Tiếp nhận hồ sơ',
            name: ['tiep-nhan-ho-so-truc-tiep', 'tiep-nhan-ho-so-truc-tuyen', 'tiep-nhan-ho-so-bo-sung'],
          },
          {
            text: 'Hồ sơ tiếp nhận trực tiếp',
            to: '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep',
            name: ['tiep-nhan-ho-so-truc-tiep'],
          },
          {
            text: 'Hồ sơ tiếp nhận trực tuyến',
            to: '/tiep-nhan-ho-so/hs-truc-tuyen/tiep-nhan-ho-so-truc-tuyen',
            name: ['tiep-nhan-ho-so-truc-tuyen'],
          },
          {
            text: 'Tiếp nhận bổ sung',
            to: '/tiep-nhan-ho-so/tn-bo-sung/tiep-nhan-ho-so-bo-sung',
            name: ['tiep-nhan-ho-so-bo-sung'],
          },
          {
            text: 'Xử lý hồ sơ',
            name: ['ban-giao-hs', 'yeu-cau-bs', 'ho-so-dang-xu-ly', 'phan-xu-ly-ho-so', 'duyet-de-xuat', 'duyet-ky-ho-so', 'duyet-gia-han', 'cho-thu-phi-le-phi', 'can-thu-phi-le-phi'],
          },
          {
            text: 'Bàn giao hồ sơ',
            to: '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs',
            name: ['ban-giao-hs'],
          },
          {
            text: 'Yêu cầu bổ sung',
            to: '/xu-ly-ho-so/yeu-cau-bs/yeu-cau-bs',
            name: ['yeu-cau-bs'],
          },
          {
            text: 'Hồ sơ đang xử lý',
            to: '/xu-ly-ho-so/ho-so-dang-xu-ly/ho-so-dang-xu-ly',
            name: ['ho-so-dang-xu-ly'],
          },
          {
            text: 'Phân xử lý hồ sơ',
            to: '/xu-ly-ho-so/phan-xu-ly-ho-so/phan-xu-ly-ho-so',
            name: ['phan-xu-ly-ho-so'],
          },
          {
            text: 'Duyệt đề xuất',
            to: '/xu-ly-ho-so/duyet-de-xuat/duyet-de-xuat',
            name: ['duyet-de-xuat'],
          },
          {
            text: 'Duyệt ký hồ sơ',
            to: '/xu-ly-ho-so/duyet-ky-ho-so/duyet-ky-ho-so',
            name: ['duyet-ky-ho-so'],
          },
          {
            text: 'Duyệt gia hạn hồ sơ',
            to: '/xu-ly-ho-so/duyet-gia-han',
            name: ['duyet-gia-han'],
          },
          {
            text: 'Hồ sơ cần thu phí, lệ phí',
            to: '/xu-ly-ho-so/can-thu-phi-le-phi/can-thu-phi-le-phi',
            name: ['can-thu-phi-le-phi'],
          },
          {
            text: 'Hồ sơ chờ thu phí, lệ phí',
            to: '/xu-ly-ho-so/cho-thu-phi-le-phi/cho-thu-phi-le-phi',
            name: ['cho-thu-phi-le-phi'],
          },
          {
            text: 'Trả kết quả',
            name: ['hs-giai-quyet', 'hs-khong-giai-quyet'],
          },
          {
            text: 'Hồ sơ giải quyết',
            to: '/tra-ket-qua/hs-giai-quyet/hs-giai-quyet',
            name: ['hs-giai-quyet'],
          },
          {
            text: 'Hồ sơ từ chối giải quyết',
            to: '/tra-ket-qua/hs-khong-giai-quyet/hs-khong-giai-quyet',
            name: ['hs-khong-giai-quyet'],
          },
          {
            text: 'Nhận kết quả',
            to: '/nhan-ket-qua/nhan-ket-qua-hs/nhan-ket-qua',
            name: ['nhan-ket-qua'],
          },
          {
            text: 'Nhận kết quả hồ sơ',
            to: '/nhan-ket-qua/nhan-ket-qua-hs/nhan-ket-qua',
            name: ['nhan-ket-qua'],
          },
          {
            text: 'Tra cứu',
            name: ['tra-cuu-ho-so'],
          },
          {
            text: 'Tra cứu hồ sơ',
            to: '/tra-cuu/tra-cuu-ho-so/tra-cuu-ho-so',
            name: ['tra-cuu-ho-so'],
          },
          {
            text: 'Chi tiết hồ sơ',
            active: true,
          },
        ],
      },
    },
    ...trangChu,
    ...pages,
    ...qldm,
    ...quanLyQuyTrinh,
    ...quanTriHeThong,
    ...tnhs,
    ...nkq,
    ...xlhs,
    ...tracuu,
    ...tkqhs,
    ...mc,
    ...bctk,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const permissions = router.app.$store.getters['common/permissions']
  if (to.query.token) {
    const payload = {
      token: to.query.token,
    }
    axios.post('https://api-motcua-mpi-test.chinhquyendientu.vn/api/v1/authentication/authenticate-by-token', payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${to.query.token}`,
      },
    }).then(res => {
      if (res.data.succeeded) {
        const listPermissions = _uniqBy(res.data.data.listPermissions, 'policy')
        if (listPermissions.length > 0) {
          setValueToStorageByKey(
            LOCAL_STORAGE_KEY.ACCESS_TOKEN,
            to.query.token,
          )
          setValueToStorageByKey(
            LOCAL_STORAGE_KEY.REFRESH_TOKEN,
            res.data.data.refreshToken,
          )
          setValueToStorageByKey(
            LOCAL_STORAGE_KEY.USER_INFO,
            res.data.data.userInformation,
          )
          setValueToStorageByKey(
            LOCAL_STORAGE_KEY.PERMISSIONS,
            listPermissions,
          )
          setValueToStorageByKey(
            LOCAL_STORAGE_KEY.IS_LOGIN,
            true,
          )
          store.commit('common/SET_PERMISSIONS', listPermissions)
          const quee = { ...to.query }
          delete quee.token
          next({
            path: to.path,
            query: quee,
          })
        } else {
          next('/login')
        }
      } else {
        next('/login')
      }
    }).catch(() => {
      next('/login')
    })
  } else if (to.fullPath !== '/login' && !getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
    next('/login')
  } else if (getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN) && to.fullPath === '/') {
    if (permissions.length === 0) {
      next('/khong-quyen')
    } else {
      let breakLoop = false
      for (let i = 0; i < ROUTER_PERMISSION.length; i += 1) {
        if (breakLoop) {
          break
        }
        for (let j = 0; j < permissions.length; j += 1) {
          if (permissions[j].name === ROUTER_PERMISSION[i].permission) {
            next(ROUTER_PERMISSION[i].path)
            breakLoop = true
            break
          }
        }
      }
    }
  } else if (to.meta && to.meta.permissionName && !hasPermission(to.meta.permissionName)) {
    next('/khong-quyen')
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
