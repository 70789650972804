import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
    name: ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
        name: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/ThuTucHanhChinh.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
        },
      },
      {
        path: '',
        name: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/ThuTucHanhChinh.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.THU_TUC_HANH_CHINH.TM_TTHC,
        name: ROUTE_NAME.THU_TUC_HANH_CHINH.TM_TTHC,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/ThemMoiThuTucHanhChinh.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
              to: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
            },
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.TM_TTHC,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.THU_TUC_HANH_CHINH.SUA_TTHC,
        name: ROUTE_NAME.THU_TUC_HANH_CHINH.SUA_TTHC,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/ThemMoiThuTucHanhChinh.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.NAME,
              to: ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
            },
            {
              text: MENU_ROUTE_NAME.THU_TUC_HANH_CHINH.DS_TTHC,
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: ROUTE_NAME.DANH_MUC.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/ThoiGianLamViecTrongTuan.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.THOI_GIAN_LAM_VIEC.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LOAI_TRANG_THAI,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LOAI_TRANG_THAI,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/LoaiTrangThai.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LOAI_TRANG_THAI,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.LOAI_TRANG_THAI.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.TRANG_THAI,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.TRANG_THAI,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/TrangThai.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.TRANG_THAI,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.TRANG_THAI.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.NHOM_PHIEU,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.NHOM_PHIEU,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/NhomPhieu.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.NHOM_PHIEU,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.NHOM_PHIEU.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.MAU_PHIEU,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.MAU_PHIEU,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/MauPhieu.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.MAU_PHIEU,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.MAU_PHIEU.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.DAN_TOC,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.DAN_TOC,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/DanToc.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.DAN_TOC,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.DAN_TOC.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LINH_VUC,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LINH_VUC,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/LinhVuc.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.LINH_VUC,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.LINH_VUC.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.QUOC_TICH,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.QUOC_TICH,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/QuocTich.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.QUOC_TICH,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.GIAY_TO,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.GIAY_TO,
        component: () => import('@/modules/danh-muc/views/danh-muc/thu-tuc-hanh-chinh/GiayTo.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.GIAY_TO,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.QUOC_TICH.DANH_SACH],
        },
      },
      // Het
      {
        path: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.BAO_CAO,
        name: ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.BAO_CAO,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/BaoCao.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_MUC.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_MUC.DM_DUNG_CHUNG.BAO_CAO,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.BAO_CAO.DANH_SACH],
        },
      },
    ],
  },
  {
    path: ROUTE_NAME.TIEN_ICH.NAME,
    name: ROUTE_NAME.TIEN_ICH.NAME,
    component: {
      template: '<router-view />',
    },
    children: [
      {
        path: ROUTE_NAME.TIEN_ICH.CONG_THANH_TOAN,
        name: ROUTE_NAME.TIEN_ICH.CONG_THANH_TOAN,
        component: () => import('@/modules/danh-muc/views/danh-muc/tien-ich/CauHinhCongThanhToan.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.NAME,
            },
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.CONG_THANH_TOAN,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.CAU_HINH_CONG_THANH_TOAN.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.TIEN_ICH.TCLDB,
        name: ROUTE_NAME.TIEN_ICH.TCLDB,
        component: () => import('@/modules/danh-muc/views/danh-muc/tien-ich/TraCuuLogDongBo.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.NAME,
            },
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.TCLDB,
              active: true,
            },
          ],
        },
      },
      {
        path: ROUTE_NAME.TIEN_ICH.TSHT,
        name: ROUTE_NAME.TIEN_ICH.TSHT,
        component: () => import('@/modules/danh-muc/views/danh-muc/tien-ich/CauHinhThamSoHeThong.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.NAME,
            },
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.TSHT,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.CAU_HINH_THAM_SO_HE_THONG.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.TIEN_ICH.THONG_TIN_KY_SO,
        name: ROUTE_NAME.TIEN_ICH.THONG_TIN_KY_SO,
        component: () => import('@/modules/danh-muc/components/pages/danh-muc/tien-ich/DanhSachKySo.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.NAME,
            },
            {
              text: MENU_ROUTE_NAME.TIEN_ICH.THONG_TIN_KY_SO,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.DANH_MUC_KY_SO],
        },
      },
    ],
  },
  {
    path: ROUTE_NAME.DANH_GIA_KHAO_SAT.NAME,
    name: ROUTE_NAME.DANH_GIA_KHAO_SAT.NAME,
    component: {
      template: '<router-view />',
    },
    children: [
      {
        path: ROUTE_NAME.DANH_GIA_KHAO_SAT.HOI_DAP,
        name: ROUTE_NAME.DANH_GIA_KHAO_SAT.HOI_DAP,
        component: () => import('@/modules/danh-muc/views/danh-muc/tien-ich/HoiDap.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.HOI_DAP,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.HOI_DAP.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_GIA_KHAO_SAT.NHOM_TIEU_CHI,
        name: ROUTE_NAME.DANH_GIA_KHAO_SAT.NHOM_TIEU_CHI,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/danh-gia/NhomTieuChi.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.NHOM_TIEU_CHI,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.NHOM_TIEU_CHI.DANH_SACH],
        },
      },
      {
        path: ROUTE_NAME.DANH_GIA_KHAO_SAT.CAU_HINH_DANH_GIA,
        name: ROUTE_NAME.DANH_GIA_KHAO_SAT.CAU_HINH_DANH_GIA,
        component: () => import('@/modules/danh-muc/views/danh-muc/dung-chung/danh-gia/CauHinhDanhGia.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.NAME,
            },
            {
              text: MENU_ROUTE_NAME.DANH_GIA_KHAO_SAT.CAU_HINH_DANH_GIA,
              active: true,
            },
          ],
        },
      },
    ],
  },
]
