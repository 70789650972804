export const ROUTE_NAME = {
  MOT_CUA: {
    NAME: '/phan-quyen/',
    PQ_LINH_VUC: 'pq-linh-vuc',
    BAO_CAO_THONG_KE: {
      NAME: 'bao-cao-tk',
      PQ_BC_LV: 'pqbclv',
      PQ_BC_DV: 'pqbcdv',
    },
    PQ_TTHC_NND: 'pqtthcnnd',
    HANH_DONG: 'hanh-dong',
    NHAT_KY_HE_THONG: 'nkht',
    HUONG_DAN_SU_DUNG: 'hdsd',
  },
}

export const MENU_ROUTE_NAME = {
  MOT_CUA: {
    NAME: 'Phân quyền',
    PQ_LINH_VUC: 'Phân quyền lĩnh vực - nhóm người dùng',
    BAO_CAO_THONG_KE: {
      NAME: 'Báo cáo thống kê',
      PQ_BC_LV: 'Phân quyền báo cáo lĩnh vực',
      PQ_BC_DV: 'Phân quyền báo cáo đơn vị',
    },
    PQ_TTHC_NND: 'Phân quyền thủ tục hành chính - vai trò',
    HANH_DONG: 'Quản lý hoạt động',
    NHAT_KY_HE_THONG: 'Nhật ký hệ thống',
    HUONG_DAN_SU_DUNG: 'Hướng dẫn sử dụng',
  },
}
