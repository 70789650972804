import { getValueFromStorageByKey, LOCAL_STORAGE_KEY } from '@/utils/local-storage'

// eslint-disable-next-line import/no-cycle
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const checkLogin = () => getValueFromStorageByKey('isLogin') === true

const getUser = () => getValueFromStorageByKey(LOCAL_STORAGE_KEY.USER_INFO)

const getPermissions = () => getValueFromStorageByKey(LOCAL_STORAGE_KEY.PERMISSIONS)

const state = {
  isLogin: checkLogin(),
  user: getUser(),
  permissions: getPermissions(),
  documentNumber: [],
  navigations: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
