// eslint-disable-next-line import/no-cycle
import axios from 'axios'
import { getValueFromStorageByKey, LOCAL_STORAGE_KEY } from '@/utils/local-storage'

// eslint-disable-next-line no-unused-vars
const layDanhSachHoSo = (context, payload) => {
  // TODO: Call api when it has done
  // callApiLayDanhSachHoSo(payload)
  //   // eslint-disable-next-line no-unused-vars
  //   .then(response => {
  //     context.commit("LAY_DANH_SACH_HO_SO_THANH_CONG", response.data);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });
}

// eslint-disable-next-line no-unused-vars
const layChiTietHoSo = (context, payload) => {
  // TODO: Call api when it has done
  context.commit('LAY_CHI_TIET_HO_SO_THANH_CONG', payload.hoSoId)
  // callApiLayDanhSachHoSo(payload)
  //   // eslint-disable-next-line no-unused-vars
  //   .then(response => {
  //     context.commit("LAY_DANH_SACH_HO_SO_THANH_CONG", response.data);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });
}

const capNhatHoSo = (context, payload) => {
  context.commit('CAP_NHAT_HO_SO_THANH_CONG', payload)
  // return new Promise((resolve, reject) => {
  //   callApiCapNhatHoSo(payload)
  //     .then(response => {
  //       context.commit("CAP_NHAT_HO_SO_THANH_CONG", response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
}

const xoaHoSo = (context, payload) => {
  context.commit('XOA_HO_SO_THANH_CONG', payload)
  // return new Promise((resolve, reject) => {
  //   callApiXoaHoSo(payload)
  //     .then(response => {
  //       context.commit("XOA_HO_SO_THANH_CONG", response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
}

const token = getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
const HTTP = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
})

const getOptionSelect = (context, url) => HTTP({
  url,
  method: 'GET',
})
export default {
  getOptionSelect,
  layDanhSachHoSo,
  capNhatHoSo,
  xoaHoSo,
  layChiTietHoSo,
}
