const isLogin = state => state.isLogin
const permissions = state => state.permissions

const documentNumber = state => state.documentNumber
const navigations = state => state.navigations

export default {
  isLogin,
  permissions,
  documentNumber,
  navigations,
}
