import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'
// eslint-disable-next-line semi
import 'vue-toastification/dist/index.css'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: false,
  closeOnClick: false,
  // closeButton: false,
  icon: false,
  timeout: 1500,
  // đặt toast mới nhất lên trên cùng
  newestOnTop: false,
  // số lượng toast tối đa
  maxToasts: 5,
  transition: 'Vue-Toastification__fade',
})
