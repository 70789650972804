import {
  callApiDanhSachNhomPhieu,
  callApiThemMoiNhomPhieu,
  callApiSuaNhomPhieu,
  callApiXoaNhomPhieu,
} from '@/modules/danh-muc/api/danh-muc-dung-chung/nhom-phieu'

const LayDanhSachNhomPhieu = (context, payload) => new Promise((resolve, reject) => {
  callApiDanhSachNhomPhieu(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // context.commit('SET_DANH_SACH_LAM_VIEC_TRONG_TUAN', res.data)
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const ThemMoiNhomPhieu = (context, payload) => new Promise((resolve, reject) => {
  callApiThemMoiNhomPhieu(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const SuaNhomPhieu = (context, payload) => new Promise((resolve, reject) => {
  callApiSuaNhomPhieu(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const XoaNhomPhieu = (context, payload) => new Promise((resolve, reject) => {
  callApiXoaNhomPhieu(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  LayDanhSachNhomPhieu,
  ThemMoiNhomPhieu,
  SuaNhomPhieu,
  XoaNhomPhieu,
}
