export const ROUTE_NAME = {
  THU_TUC_HANH_CHINH: {
    NAME: '/thu-tuc-hanh-chinh',
    DS_TTHC: 'ds-tthc',
    TM_TTHC: 'tm-tthc',
    SUA_TTHC: 'sua-tthc',
  },
  DANH_MUC: {
    NAME: '/danh-muc/',
    DM_DUNG_CHUNG: {
      NAME: 'dmdc',
      THOI_GIAN_LAM_VIEC_TRONG_TUAN: 'ttlvtt',
      LOAI_TRANG_THAI: 'loai-trang-thai',
      TRANG_THAI: 'trang-thai',
      NHOM_PHIEU: 'nhom-phieu',
      MAU_PHIEU: 'mau-phieu',
      DAN_TOC: 'dan-toc',
      LINH_VUC: 'linh-vuc',
      BAO_CAO: 'bao-cao',
      QUOC_TICH: 'quoc-tich',
      CHKSDG: 'chksdg',
      HANH_DONG: 'hanh-dong',
      BIEU_MAU: 'bieu-mau',
      DANH_GIA: 'danh-gia',
      GIAY_TO: 'giay-to',
    },
    THU_TUC_HANH_CHINH: {
      NAME: 'thu-tuc-hanh-chinh',
      TTHC: 'tthc',
      MAU_HO_SO: 'mau-ho-so',
      PQ_TTHC: 'pqtthc',
      PQ_TTHC_NND: 'pqtthcnnd',
      MAPP_LINH_VUC: 'mapping-linh-vuc',
      MAPP_DON_VI: 'mapping-don-vi',
      MAPP_THU_TUC_HANH_CHINH: 'mapping-tthc',
      MAPP_TRANG_THAI_HO_SO: 'mapping-tths',
    },
  },
  TIEN_ICH: {
    NAME: '/tien-ich',
    CONG_THANH_TOAN: 'cong-thanh-toan',
    TCLDB: 'tra-cuu-dong-bo',
    TSHT: 'tham-so-he-thong',
    THONG_TIN_KY_SO: 'thong-tin-ky-so',
  },
  DANH_GIA_KHAO_SAT: {
    NAME: '/danh-gia-khao-sat',
    HOI_DAP: 'hoi-dap',
    PHUONG_THUC_DANH_GIA: 'phuong-thuc-danh-gia',
    NHOM_TIEU_CHI: 'nhom-tieu-chi',
    TTTCDG: 'ttcdg',
    TIEU_CHI_DANH_GIA: 'tieu-chi-danh-gia',
    CAU_HINH_DANH_GIA: 'tieu-chi-danh-gia',
  },
}

export const MENU_ROUTE_NAME = {
  THU_TUC_HANH_CHINH: {
    NAME: 'Danh mục thủ tục hành chính',
    DS_TTHC: 'Danh sách thủ tục hành chính',
    TM_TTHC: 'Thêm mới',
    SUA_TTHC: 'Cập nhật',
  },
  DANH_MUC: {
    NAME: 'Quản lý danh mục',
    DM_DUNG_CHUNG: {
      NAME: 'Danh mục dùng chung',
      THOI_GIAN_LAM_VIEC_TRONG_TUAN: 'Danh mục thời gian làm việc trong tuần',
      LOAI_TRANG_THAI: 'Danh mục loại trạng thái',
      TRANG_THAI: 'Danh mục trạng thái',
      NHOM_PHIEU: 'Danh mục nhóm phiếu',
      MAU_PHIEU: 'Danh mục mẫu phiếu',
      DAN_TOC: 'Danh mục dân tộc',
      LINH_VUC: 'Danh mục lĩnh vực',
      QUOC_TICH: 'Danh mục quốc tịch',
      BAO_CAO: 'Danh mục báo cáo',
      CHKSDG: 'Danh mục câu hỏi khảo sát, đánh giá',
      HANH_DONG: 'Danh mục hành động',
      BIEU_MAU: 'Danh mục biểu mẫu',
      DANH_GIA: 'Danh mục đánh giá',
      GIAY_TO: 'Danh mục giấy tờ',
    },
    THU_TUC_HANH_CHINH: {
      NAME: 'Danh mục thủ tục hành chính',
      TTHC: 'Quản lý thủ tục hành chính',
      MAU_HO_SO: 'Quản lý mẫu hồ sơ',
      PQ_TTHC_NND: 'Phân quyền thủ tục hành chính - vai trò',
      MAPP_LINH_VUC: 'Mapping Lĩnh Vực',
      MAPP_DON_VI: 'Mapping đơn vị',
      MAPP_THU_TUC_HANH_CHINH: 'Mapping thủ tục hành chính',
      MAPP_TRANG_THAI_HO_SO: 'Mapping trạng thái hồ sơ',
    },
  },
  TIEN_ICH: {
    NAME: 'Tiện ích',
    CONG_THANH_TOAN: 'Cấu hình cổng thanh toán',
    TCLDB: 'Tra cứu log đồng bộ',
    TSHT: 'Tham số hệ thống',
    THONG_TIN_KY_SO: 'Danh sách thông tin ký số',
  },
  DANH_GIA_KHAO_SAT: {
    NAME: 'Đánh giá, khảo sát',
    HOI_DAP: 'Hỏi đáp thắc mắc',
    PHUONG_THUC_DANH_GIA: 'Phương thức đánh giá',
    NHOM_TIEU_CHI: 'Nhóm tiêu chí',
    TTTCDG: 'Thuộc tính tiêu chí đánh giá',
    TIEU_CHI_DANH_GIA: 'Câu hỏi khảo sát',
    CAU_HINH_DANH_GIA: 'Câu hỏi khảo sát',
  },
}

export const KIEU_THU_TUC_HANH_CHINH = [
  {
    id: 1,
    label: 'Liên thông',
    code: 1,
    isDefault: false,
  },
  {
    id: 2,
    label: 'Không liên thông',
    code: 2,
    isDefault: false,
  },
]

export const LOAI_DICH_VU_CONG = [
  {
    id: 1,
    label: 'Dịch vụ công trực tuyến toàn trình',
    code: 1,
    isDefault: false,
  },
  {
    id: 0,
    label: 'Dịch vụ công trực tuyến một phần',
    code: 0,
    isDefault: false,
  },
  {
    id: 3,
    label: 'Dịch vụ cung cấp thông tin trực tuyến',
    code: 3,
    isDefault: false,
  },
]

export const MUC_DO_VU_CONG = [
  {
    id: 1,
    label: 'Mức độ 1',
    code: 1,
    isDefault: false,
  },
  {
    id: 2,
    label: 'Mức độ 2',
    code: 2,
    isDefault: false,
  },
  {
    id: 3,
    label: 'Mức độ 3',
    code: 3,
    isDefault: true,
  },
  {
    id: 4,
    label: 'Mức độ 4',
    code: 4,
    isDefault: false,
  },
]

export const CAP_DO_TTHC = [
  {
    id: 1,
    label: 'Nội bộ',
    code: 1,
    isDefault: true,
  },
  {
    id: 2,
    label: 'Liên thông 2 cấp',
    code: 2,
    isDefault: false,
  },
  {
    id: 3,
    label: 'Liên thông 3 cấp',
    code: 3,
    isDefault: true,
  },
]

export const OPTIONS_CDCTTTHC = [
  { text: 'Cấp TW', value: 1 },
  { text: 'Cấp tỉnh', value: 2 },
  { text: 'Cấp huyện', value: 3 },
  { text: 'Liên thông hệ thống khác', value: 4 },
]

export const STATUS = [
  {
    id: true,
    label: 'Hoạt động',
  },
  {
    id: false,
    label: 'Ngưng hoạt động',
  },
]

export const KIEU_TIEU_CHI_DANH_GIA = [
  {
    id: 1,
    label: 'Đánh giá thủ tục hành chính',
  },
  {
    id: 2,
    label: 'Đánh giá cán bộ',
  },
  {
    id: 3,
    label: 'Khảo sát',
  },
]
