export const ROUTE_NAME = {
  NAME: '/quan-tri-he-thong',
  FORM: {
    DANH_SACH: '/danh-sach-form',
    THEM_MOI: '/them-moi-form',
    SUA: '/sua-form',
    CHI_TIET: '/chi-tiet',
  },
  NAVIGATION: 'navigations',
  LOGS_ASYNC: 'logs',
  LOGS_DVCQG: 'logs_dvcqg',
}

export const MENU_ROUTE_NAME = {
  NAME: 'Quản lý danh mục',
  FORM: {
    DANH_SACH: 'Danh mục biểu mẫu',
    THEM_MOI: 'Thêm mới biểu mẫu',
    SUA: 'Cập nhật biểu mẫu',
    CHI_TIET: 'Chi tiết biểu mẫu',
  },
  NAVIGATION: 'Cấu hình điều hướng',
  LOG: 'Danh sách đồng bộ',
  LOG_DVCQG: 'Tra cứu hồ sơ DVCQG',
}

export const CLASS_CONFIG = [
  'col-md-1',
  'col-md-2',
  'col-md-3',
  'col-md-4',
  'col-md-5',
  'col-md-6',
  'col-md-7',
  'col-md-8',
  'col-md-9',
  'col-md-10',
  'col-md-11',
  'col-md-12',
]

export const CONTROL_LIST = [
  {
    title: 'Input',
    icon: 'input.svg',
    expand: false,
    children: [
      {
        id: 'input-text',
        type: 'text',
        label: 'inputText',
        unit: 'Kiểu chữ',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'configlength',
          'configicon',
        ],
        validateSelected: ['required'],
        validation: 'required',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'input-email',
        type: 'text',
        label: 'inputEmail',
        unit: 'Kiểu chữ',
        dbtype: 'nvarchar(MAX)',
        validation: 'required|email',
        validateSelected: ['required'],
        showElement: [
          'configicon',
        ],
        'validation-messages': {
          email: 'Email không đúng định dạng',
          required: 'Trường không được để trống',
          regex: '',
        },
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'input-url',
        type: 'text',
        label: 'inputUrl',
        unit: 'Kiểu chữ',
        dbtype: 'nvarchar(MAX)',
        validation: 'required|url',
        validateSelected: ['required'],
        showElement: [
          'configicon',
        ],
        'validation-messages': {
          url: 'Trường không đúng định dạng',
          required: 'Trường không được để trống',
          regex: '',
        },
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'input-pw',
        type: 'password',
        label: 'password',
        unit: 'Mật khẩu',
        dbtype: 'nvarchar(MAX)',
        validation: 'required',
        validateSelected: ['required'],
        showElement: [
          'configicon',
        ],
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'color',
        type: 'color',
        label: 'color',
        value: '#3eaf7c',
        dbtype: 'nvarchar(MAX)',
        unit: 'Kiểu chữ',
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'number',
        type: 'number',
        label: 'inputNumber',
        unit: 'Kiểu số',
        dbtype: 'nvarchar(MAX)',
        validation: 'required',
        validateSelected: ['required'],
        showElement: [
          'configicon',
          'configrange',
        ],
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'range',
        type: 'range',
        label: 'range',
        unit: 'Kiểu số',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'configrange',
        ],
        max: 500,
        min: 0,
        value: 45,
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'date',
        type: 'date',
        label: 'date',
        dbtype: 'date',
        format: 'DD/MM/YYYY',
        valueformat: 'dd/mm/yyyy',
        validation: 'required',
        validateSelected: ['required'],
        unit: 'Kiểu chữ',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'month',
        type: 'month',
        label: 'month',
        dbtype: 'nvarchar(MAX)',
        validation: 'required',
        validateSelected: ['required'],
        unit: 'Kiểu chữ',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'time',
        type: 'time',
        label: 'time',
        dbtype: 'nvarchar(MAX)',
        validation: 'required',
        validateSelected: ['required'],
        unit: 'Kiểu chữ',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'datetime-local',
        type: 'datetime-local',
        label: 'datetime',
        dbtype: 'datetime',
        format: 'DD/MM/YYYY HH:mm A',
        valueformat: 'dd/mm/yyyy HH:mm A',
        validation: 'required',
        validateSelected: ['required'],
        unit: 'Kiểu ngày',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
  {
    title: 'Text-Area',
    icon: 'text-area.svg',
    expand: false,
    children: [
      {
        id: 'textarea',
        type: 'textarea',
        label: 'textarea',
        dbtype: 'nvarchar(MAX)',
        validation: 'required',
        validateSelected: ['required'],
        unit: 'Kiểu chữ',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'ckeditor',
        type: 'ckeditor',
        label: 'ckeditor',
        dbtype: 'nvarchar(MAX)',
        unit: 'Kiểu chữ',
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
  {
    title: 'Dropdown',
    icon: 'dropdown.svg',
    expand: false,
    children: [
      {
        id: 'select',
        type: 'select',
        label: 'select',
        size: 1,
        options: [
          { value: 'first', label: 'First name', group: '' },
          { value: 'last', label: 'Last name', group: '' },
          { value: 'initial', label: 'Middle Initial', group: '' },
          { value: 'maiden', label: 'Maiden name', group: '' },
        ],
        unit: 'Kiểu json',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'table',
          'groupApi',
        ],
        apiData: false,
        configApi: {
          name: '',
          label: '',
          group: '',
        },
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'treeSelect',
        type: 'treeSelect',
        label: 'treeSelect',
        multiple: true,
        size: 1,
        options: [
          { value: 'first', label: 'First name', group: '' },
          { value: 'last', label: 'Last name', group: '' },
          { value: 'initial', label: 'Middle Initial', group: '' },
          { value: 'maiden', label: 'Maiden name', group: '' },
        ],
        unit: 'Kiểu json',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'table',
          'groupApi',
        ],
        apiData: false,
        configApi: {
          name: '',
          label: '',
          group: '',
        },
        group: false,
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
  {
    title: 'Checkbox',
    icon: 'radio.svg',
    expand: false,
    children: [
      {
        id: 'checkbox',
        type: 'checkbox',
        label: 'checkbox',
        unit: 'Kiểu json',
        dbtype: 'nvarchar(MAX)',
        options: [
          { value: 'first', label: 'First name', group: '' },
          { value: 'last', label: 'Last name', group: '' },
          { value: 'initial', label: 'Middle Initial', group: '' },
          { value: 'maiden', label: 'Maiden name', group: '' },
        ],
        showElement: [
          'table',
        ],
        apiData: false,
        configApi: {
          name: '',
          label: '',
          group: '',
        },
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'radio',
        type: 'radio',
        label: 'radio',
        options: [
          { value: 'first', label: 'First name', group: '' },
          { value: 'last', label: 'Last name', group: '' },
          { value: 'initial', label: 'Middle Initial', group: '' },
          { value: 'maiden', label: 'Maiden name', group: '' },
        ],
        unit: 'Kiểu chữ',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'table',
        ],
        apiData: false,
        configApi: {
          name: '',
          label: '',
          group: '',
        },
        styleClasses: 'display-inline',
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
  {
    title: 'Upload',
    icon: 'upload.svg',
    expand: false,
    children: [
      {
        id: 'file',
        type: 'file',
        label: 'UploadFile',
        unit: 'Kiểu json',
        dbtype: 'nvarchar(MAX)',
        multiple: true,
        showElement: [
          'file',
        ],
        fileConfig: {
          capacity: '10',
          prop: '',
          name: '',
          size: '',
          params: '',
          dataType: '.doc,.docx,.xlsx,.pdf,.png,.jpg,.jpeg',
          uploadFolder: '',
          apiDownload: '',
          apiUpload: '',
          body: '',
        },
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
  {
    title: 'Custom',
    icon: 'custom.svg',
    expand: false,
    children: [
      {
        id: 'html',
        type: 'html',
        label: 'html',
        html: '<p>sửa lại dòng chữ này</p>',
        tooltip: '<p>sửa lại dòng chữ này</p>',
        unit: 'Kiểu chữ',
        dbtype: 'nvarchar(MAX)',
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'switches',
        type: 'switches',
        label: 'switches',
        unit: 'Kiểu logic',
        dbtype: 'nvarchar(MAX)',
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
      {
        id: 'table',
        type: 'table',
        label: 'table',
        unit: 'Kiểu json',
        dbtype: 'nvarchar(MAX)',
        showElement: [
          'config-table',
        ],
        options: [
          { columnName: 'Kiểu số', attribute: 'Number', dataType: 'Number' },
          { columnName: 'Kiểu chữ', attribute: 'Text', dataType: 'Text' },
          { columnName: 'Kiểu ngày', attribute: 'Date', dataType: 'Date time' },
        ],
        validation: '',
        isHideExpression: false,
        hideExpression: '',
        isPartternValidation: false,
        partternValue: '',
      },
    ],
  },
]

export const FONT_AWESOME = [
  'trang-chu-icon.svg',
  'tiep-nhan-ho-so-icon.svg',
  'xu-ly-ho-so-icon.svg',
  'tra-ket-qua-icon.svg',
  'nhan-ket-qua-icon.svg',
  'tra-cuu-icon.svg',
  'quy-trinh-icon.svg',
  'thu-tuc-hanh-chinh-icon.svg',
  'danh-muc-icon.svg',
  'tien-ich-icon.svg',
  'danh-gia-khao-sat-icon.svg',
  'phan-quyen-icon.svg',
  'nhat-ki-he-thong-icon.svg',
  'bao-cao-thong-ke-icon.svg',
  'nhap-lieu-bao-cao.svg',
]
