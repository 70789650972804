import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { library } from '@fortawesome/fontawesome-svg-core'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import vSelect from 'vue-select'
import plugins from '@/plugins/plugins'

import Treeselect from '@riophae/vue-treeselect'

import {
  VeTable, VePagination,
} from 'vue-easytable'
import i18n from '@/libs/i18n'
import VueFormulate from '@braid/vue-formulate'
import CKEditor2 from '@ckeditor/ckeditor5-vue2'
import FormTreeSelect from '@/modules/quan-tri-he-thong/components/pages/custom-control/FormTreeSelect.vue'
import FormButtonSwitch from '@/modules/quan-tri-he-thong/components/pages/custom-control/FormButtonSwitch.vue'
import FormCKEditor from '@/modules/quan-tri-he-thong/components/pages/custom-control/FormCKEditor.vue'
import FormTable from '@/modules/quan-tri-he-thong/components/pages/custom-control/FormTable.vue'
import FormFile from '@/modules/quan-tri-he-thong/components/pages/custom-control/FormFile.vue'
import MyCustomLabel from '@/modules/quan-tri-he-thong/components/pages/custom-control/MyCustomLabel.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueApexCharts from 'vue-apexcharts'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vue-easytable/libs/theme-default/index.css'
import 'vue-select/dist/vue-select.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/toasted'
import '@/libs/formatvselect'
import '@/utils/print-utils'

// Axios Mock Adapter
import '@/@fake-db/db'

Vue.use(plugins)

Vue.use(VueApexCharts)

/* add icons to the library */
library.add(fas)
library.add(far)

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('v-select', vSelect)

Vue.component('treeselect', Treeselect)

Vue.component('ValidationProvider', ValidationProvider)

Vue.component('ValidationObserver', ValidationObserver)

Vue.component('apexchart', VueApexCharts)

Vue.use(CKEditor2)
Vue.use(VueFormulate, {
  // classes: {
  //   outer: 'form-group',
  //   inputHasErrors: 'is-invalid',
  //   help: 'form-text text-muted',
  //   errors: 'list-unstyled text-danger'
  // },
  library: {
    treeSelect: {
      classification: 'text',
      component: FormTreeSelect,
    },
    switches: {
      classification: 'text',
      component: FormButtonSwitch,
    },
    ckeditor: {
      classification: 'text',
      component: FormCKEditor,
    },
    table: {
      classification: 'text',
      component: FormTable,
    },
    customFile: {
      classification: 'text',
      component: FormFile,
    },
  },
  slotComponents: {
    label: MyCustomLabel,
  },
  slotProps: {
    label: ['tooltip'],
  },
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VeTable)
Vue.use(VePagination)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const loading = Vue.observable({ load: false })

Object.defineProperty(Vue.prototype, '$isLoading', {
  get() {
    return loading.load
  },

  set(value) {
    loading.load = value
  },
})

const checked = Vue.observable({ isDisabelBtn: 0 })

Object.defineProperty(Vue.prototype, '$isDisabelBtn', {
  get() {
    return checked.isDisabelBtn
  },

  set(value) {
    checked.isDisabelBtn = value
  },
})

global.jQuery = require('jquery')

const $ = global.jQuery
window.$ = $

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
