// eslint-disable-next-line no-unused-expressions, func-names
!(function (n) {
  // eslint-disable-next-line no-unused-expressions, no-multi-assign, no-param-reassign
  const e = n.vi = n.vi || {}; e.dictionary = Object.assign(e.dictionary || {}, {
    '%0 of %1': '%0 đến %1',
    Aquamarine: 'Xanh ngọc biển',
    Alignment: 'Căn chỉnh',
    'Align table to the left': 'Căn chỉnh bên trái',
    'Center table': 'Căn chỉnh giữa',
    'Align table to the right': 'Căn chỉnh bên phải',
    'Align left': 'Căn trái',
    'Align right': 'Căn phải',
    'Align center': 'Căn giữa',
    'Align cell text to the left': 'Căn ô sang trái',
    'Align cell text to the right': 'Căn ô sang phải',
    'Align cell text to the center': 'Căn ô ở giữa',
    'Align cell text to the top': 'Căn chỉnh ô lên đầu',
    'Align cell text to the middle': 'Căn chỉnh ô ở giữa',
    'Align cell text to the bottom': 'Căn chỉnh ô ở dưới',
    Justify: 'Căn đều',
    Black: 'Đen',
    'Block quote': 'Trích dẫn',
    Blue: 'Xanh biển',
    Bold: 'Đậm',
    'Break text': 'Ngắt văn bản',
    Border: 'Đường viền',
    Background: 'Phông nền',
    'Bulleted List': 'Danh sách đánh ký hiệu',
    Cancel: 'Hủy',
    'Cannot upload file:': 'Không thể tải file:',
    'Centered image': 'Ảnh canh giữa',
    'Change image text alternative': 'Đổi chữ alt của ảnh',
    'Choose heading': 'Chọn tiêu đề',
    Column: 'Cột',
    'Could not insert image at the current position.': 'Không thể chèn ảnh ở vị trí hiện tại',
    'Could not obtain resized image URL.': 'Không thể lấy được đường dẫn ảnh đã đổi kích thước',
    Color: 'Màu',
    'Color picker': 'Bộ chọn màu',
    Circle: 'Tròn',
    Code: 'Mã số',
    'Cell properties': 'Thuộc tính ô',
    'Decrease indent': 'Giảm lề',
    'Delete column': 'Xoá cột',
    'Delete row': 'Xoá hàng',
    'Dim grey': 'Xám mờ',
    Downloadable: 'Có thể tải về',
    'Dropdown toolbar': 'Thanh công cụ danh mục',
    Dimensions: 'Kích thước',
    Disc: 'Chấm',
    Decimal: 'Số thập phân',
    'Decimal with leading zero': 'Số thập phân với số 0 ở đầu',
    'Edit block': 'Chỉnh sửa đoạn',
    'Edit link': 'Sửa liên kết',
    'Editor toolbar': 'Thanh công cụ biên tập',
    'Enter image caption': 'Nhập mô tả ảnh',
    'Enter table caption': 'Nhập mô tả bảng',
    'Full size image': 'Ảnh đầy đủ',
    Green: 'Xanh lá',
    Grey: 'Xám',
    'Header column': 'Tiêu đề cột',
    'Header row': 'Tiêu đề hàng',
    Heading: 'Tiêu đề',
    'Heading 1': 'Tiêu đề 1',
    'Heading 2': 'Tiêu đề 2',
    'Heading 3': 'Tiêu đề 3',
    'Heading 4': 'Tiêu đề 4',
    'Heading 5': 'Tiêu đề 5',
    'Heading 6': 'Tiêu đề 6',
    Height: 'Dài',
    'Image toolbar': 'Thanh công cụ hình ảnh',
    'image widget': 'tiện ích ảnh',
    'In line': 'Trong hàng',
    'Increase indent': 'Tăng lề',
    'Insert column left': 'Thêm cột vào bên trái',
    'Insert column right': 'Thêm cột vào bên phải',
    'Insert image': 'Chèn ảnh',
    'Insert image or file': 'Chèn ảnh hoặc file',
    'Insert media': 'Chèn đa phương tiện',
    'Insert paragraph after block': 'Chèn sau khối',
    'Insert paragraph before block': 'Chèn trước khối',
    'Insert row above': 'Thêm hàng phía trên',
    'Insert row below':
    'Thêm hàng ở dưới',
    'Insert table': 'Tạo bảng',
    'Inserting image failed': 'Chèn ảnh thất bại',
    Italic: 'Nghiêng',
    'Left aligned image': 'Ảnh canh trái',
    'Light blue': 'Xanh dương',
    'Light green': 'Xanh lá nhạt',
    'Light grey': 'Xám nhạt',
    'Justify cell text': 'Căn đều ô',
    Link: 'Chèn liên kết',
    'Link URL': 'Đường dẫn liên kết',
    'Lower-roman': 'Số la mã thường',
    'Lower-latin': 'Chữ latin thường',
    'Media URL': 'Đường dẫn đa phương tiện',
    'media widget': 'tiện ích đa phương tiện',
    'Merge cell down': 'Sát nhập ô xuống dưới',
    'Merge cell left': 'Sát nhập ô qua trái',
    'Merge cell right': 'Sát nhập ô qua phải',
    'Merge cell up': 'Sát nhập ô lên trên',
    'Merge cells': 'Sát nhập ô',
    Next: 'Tiếp theo',
    'Numbered List': 'Danh sách đánh số',
    'Open in a new tab': 'Mở trên tab mới',
    'Open link in new tab': 'Mở liên kết',
    Orange: 'Cam',
    Paragraph: 'Đoạn văn',
    'Paste the media URL in the input.': 'Dán đường dẫn đa phương tiện vào trường',
    Previous: 'Quay lại',
    Purple: 'Tím',
    'Page break': 'Ngắt trang',
    Padding: 'Khoảng trống',
    Red: 'Đỏ',
    Redo: 'Tiếp tục',
    'Rich Text Editor': 'Trình soạn thảo văn bản',
    'Rich Text Editor, %0': 'Trình soạn thảo văn bản, %0',
    'Right aligned image': 'Ảnh canh phải',
    Row: 'Hàng',
    'Remove color': 'Xóa màu',
    Save: 'Lưu',
    'Select column': 'Chọn cột',
    'Select row': 'Chọn hàng',
    'Selecting resized image failed': 'Chọn ảnh đã đổi kích thước thất bại',
    'Show more items': 'Xem thêm',
    'Side image': 'Ảnh một bên',
    'Split cell horizontally': 'Tách ô theo chiều ngang',
    'Split cell vertically': 'Tách ô theo chiều dọc',
    Strikethrough: 'Gạch ngang',
    Style: 'Kiểu',
    Subscript: 'Chỉ số dưới',
    Superscript: 'Chỉ số trên',
    Square: 'Vuông',
    'Table toolbar': 'Thanh công cụ bảng',
    'Table properties': 'Thuộc tính bảng',
    'Text alternative': 'Chữ alt',
    'The URL must not be empty.': 'Đường dẫn không được để trống',
    'This link has no URL': 'Liên kết không có đường dẫn',
    'This media URL is not supported.': 'Đường dẫn đa phương tiện không hỗ trợ',
    'Tip: Paste the URL into the content to embed faster.': 'Mẹo: Dán đường dẫn vào nội dung để nhúng ngay',
    'Toggle caption off': 'Tắt chú thích',
    'Toggle caption on': 'Bật chú thích',
    Turquoise: 'Xanh ngọc bích',
    'Text alignment': 'Căn chỉnh văn bản',
    'Table cell text alignment': 'Cản chỉnh văn bản',
    Undo: 'Hoàn tác',
    Unlink: 'Bỏ liên kết',
    Underline: 'Gạch chân',
    'Upload failed': 'Tải thất bại',
    'Upload in progress': 'Đang tải lên',
    'Upper-roman': 'Số la mã hoa',
    'Upper-latin': 'Chữ latin hoa',
    White: 'Trắng',
    'Widget toolbar': 'Thanh công cụ tiện ích',
    'Wrap text': 'Bao văn bản',
    Width: 'Rộng',
    Yellow: 'Vàng',
  // eslint-disable-next-line no-unused-vars, no-shadow, func-names, no-sequences
  }), e.getPluralForm = function (n) { return 0 }
}(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {})))
