export default {
  install(Vue) {
    const vueContext = Vue
    vueContext.prototype.$trimObject = function (obj) {
      const objTemp = obj
      Object.keys(objTemp).forEach(k => {
        if (typeof (objTemp[k]) === 'string') {
          objTemp[k] = objTemp[k].replace(/\s+/g, ' ').trim()
        }
      })
      return objTemp
    }

    vueContext.prototype.$removeDiacritical = str => str.toLowerCase().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[đĐ]/g, 'd')
      .replace(/^-+|-+$/g, '')

    vueContext.directive('block-e', {
      bind: el => {
        const handler = e => {
          if (e.keyCode === 69 || e.keyCode === 231) {
            e.preventDefault()
          }
        }
        el.addEventListener('keydown', handler)
      },
    })
  },
}
