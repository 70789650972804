import {
  callApiDanhSachTrangThai,
  callApiThemMoiTrangThai,
  callApiSuaTrangThai,
  callApiXoaTrangThai,
} from '@/modules/danh-muc/api/danh-muc-dung-chung/trang-thai'

const LayDanhSachTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiDanhSachTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // context.commit('SET_DANH_SACH_LAM_VIEC_TRONG_TUAN', res.data)
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const ThemMoiTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiThemMoiTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const SuaTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiSuaTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const XoaTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiXoaTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  LayDanhSachTrangThai,
  ThemMoiTrangThai,
  SuaTrangThai,
  XoaTrangThai,
}
