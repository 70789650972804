<template>
  <div>
    <p class="mb-0">
      {{ fileName.toString() }}
    </p>
    <!-- v-if="!getDisabled" -->
    <label
      for="upload"
      class="mt-1 upload-label"
    >
      <feather-icon
        icon="UploadIcon"
        size="18"
      />
      {{ $parent.label }}
    </label>
    {{ validateFile }}
    <input
      id="upload"
      ref="fileUpload"
      type="file"
      hidden="true"
      multiple
      :accept="acceptFile"
      @change="postFile"
    >
  </div>
</template>

<script>

export default {
  name: 'FormFile',
  data() {
    return {
      fileType: [],
      validateFile: '',
      fileName: [],
    }
  },
  computed: {
    acceptFile() {
      return this.$parent.$attrs.fileConfig.dataType
    },
    getDisabled() {
      return !!this.$parent.$attrs.disabled
    },
  },
  methods: {
    postFile() {
      let checkSize = false
      const { files } = this.$refs.fileUpload
      for (let i = 0; i < files.length; i += 1) {
        this.fileName.push(files[i].name)
        const cfile = files[i]
        // megabyte => byte
        const maxsize = this.$parent.$attrs.fileConfig.capacity * 1048576
        if (cfile.size > maxsize) {
          checkSize = true
        }
      }
      if (checkSize) {
        this.validateFile = 'File vượt quá dung lượng cho phép'
        this.$refs.fileUpload.value = null
        return
      }
      this.validateFile = ''
      this.$emit('control-value', files)
    },
  },
}
</script>

<style scoped>
.upload-label {
  padding: 5px 10px;
  background-color: #7367f0;
  color: white;
}
</style>
