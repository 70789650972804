import Vue from 'vue'

Vue.prototype.$print = {
  formatFullDateTime(date, type = 'DD/MM/YYYY') {
    let newDate = new Date()
    if (date) {
      newDate = new Date(date)
    }
    if (type === 'DD/MM/YYYY') {
      return `ngày ${newDate.getDate()} tháng ${newDate.getMonth() + 1} năm ${newDate.getFullYear()}`
    }
    if (type === 'DD') {
      return newDate.getDate()
    }
    if (type === 'MM') {
      return newDate.getMonth() + 1
    }
    if (type === 'YYYY') {
      return newDate.getFullYear()
    }
    return `${newDate.getHours()} giờ ${newDate.getMinutes()} phút`
  },
  formatGio(gio) {
    if (gio) {
      const data = gio.split(':')
      return `${data[0]} giờ ${data[1]} phút`
    }
    return ''
  },
  formatStringTable(data) {
    let str = ''
    if (data && data.length > 0) {
      data.forEach(item => {
        str += `${item.tenGiayTo}\n`
      })
    } else {
      str = 'Không có'
    }
    return str
  },
  formatTable(data) {
    let str = ''
    if (data && data.length > 0) {
      str = `<table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
            <tr>
              <th style="border: 1px solid black; padding: 10px; width: 35px !important; text-align: center;">
                STT
              </th>
              <th style="border: 1px solid black; padding: 10px; text-align: center;">
                Tên giấy tờ
              </th>
              <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                Bản chính
              </th>
              <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                Bản sao
              </th>
              <th style="border: 1px solid black; padding: 10px; width: 100px !important; text-align: center;">
                Bản photo
              </th>
            </tr>`
      data.forEach((item, index) => {
        str += `
              <tr>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  ${index + 1}
                </td>
                <td style="border: 1px solid black; padding: 10px;">
                  ${item.tenGiayTo}
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  ${item.banChinh || ''}
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  ${item.banSao || ''}
                </td>
                <td style="border: 1px solid black; padding: 10px; text-align: center;">
                  ${item.banPhoTo || ''}
                </td>
              </tr>
            `
      })
      str += '</table>'
    } else {
      str = 'Không có'
    }
    return str
  },
  formatImg(data, className) {
    // eslint-disable-next-line no-nested-ternary
    return `<img src="data:image/png;base64, ${data}" style="${className.indexOf('barCodeBase64') >= 0 ? 'width: 250px; height: 50px;' : className.indexOf('qrCodeBase64') >= 0 ? 'width: 100px; height: 100px;' : 'width: 100px; height: 100px;'}">`
  },
  onClickToPrint(vm) {
    window.frames.print_frame.document.title = document.title
    window.frames.print_frame.document.body.innerHTML = `<!DOCTYPE html>
    <html>
      <title> In hồ sơ theo biểu mẫu </title>
      <style>
        table {
          width: 100% !important;
        }
        
        .table {
          tbody {
            height: 100% !important;
          }
          tr {
            width: 100% !important;
          }
          td {
            border-top: none !important;
          }
        }
        @page {
          size: auto A4 landscape;
          margin: 3mm;
        }
      </style>
      <body>
        ${vm.$refs.docBody.innerHTML}
      </body>
    </html>`
    window.frames.print_frame.window.focus()
    window.frames.print_frame.window.print()
  },
  onExportFileWord(vm) {
    const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
    <head>
      <meta charset='utf-8'>
      <title>Export HTML To Doc</title>
      <style>
        table {
          width: 100% !important;
        }
      </style>
    </head>
    <body>
    `
    const postHtml = '</body></html>'
    const printEle = vm.$refs.docBody.innerHTML
    const html = preHtml + printEle + postHtml
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    })
    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(html)}`
    // Specify file name
    const filename = 'giao-nhan-ho-so.doc'
    // Create download link element
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      // Create a link to the file
      downloadLink.href = url
      // Setting the file name
      downloadLink.download = filename
      // triggering the function
      downloadLink.click()
    }
    document.body.removeChild(downloadLink)
  },
}
