import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './modules/app'
import appConfig from './modules/app-config'
import verticalMenu from './modules/vertical-menu'
// eslint-disable-next-line import/no-cycle
import qtht from '../modules/quan-tri-he-thong/store'
// eslint-disable-next-line import/no-cycle
import common from './modules/common'
import danhMuc from '../modules/danh-muc/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    common,
    qtht,
    danhMuc,
  },
  strict: process.env.DEV,
})
