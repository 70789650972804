import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/modules/danh-muc/api/endpoints'

export function callApiDanhSachBaoCao(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.BAO_CAO.DANH_SACH,
    method: 'GET',
    params: payload,
  })
}

export function callApiThemMoiBaoCao(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.BAO_CAO.THEM_MOI,
    method: 'POST',
    data: payload,
  })
}

export function callApiSuaBaoCao(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.BAO_CAO.SUA,
    method: 'POST',
    data: payload,
  })
}

export function callApiXoaBaoCao(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.BAO_CAO.XOA,
    method: 'DELETE',
    data: payload,
  })
}
