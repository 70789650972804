import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/tra-cuu/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.TC_HO_SO.NAME,
        name: ROUTE_NAME.TC_HO_SO.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.TC_HO_SO.DS,
            name: ROUTE_NAME.TC_HO_SO.DS,
            component: () => import('@/modules/tra-cuu/components/pages/tra-cuu/TraCuuHoSo.vue'),
            meta: {
              code: ROUTE_NAME.TC_HO_SO.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.TRA_CUU],
            },
          },
        ],
      },
    ],
  },
]
