import {
  callApiDanhSachLoaiTrangThai,
  callApiThemMoiLoaiTrangThai,
  callApiSuaLoaiTrangThai,
  callApiXoaLoaiTrangThai,
} from '@/modules/danh-muc/api/danh-muc-dung-chung/loai-trang-thai'

const LayDanhSachLoaiTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiDanhSachLoaiTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        context.commit('SET_DANH_SACH_LOAI_TRANG_THAI', res.data.data)
        resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const ThemMoiLoaiTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiThemMoiLoaiTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const SuaLoaiTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiSuaLoaiTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const XoaLoaiTrangThai = (context, payload) => new Promise((resolve, reject) => {
  callApiXoaLoaiTrangThai(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  LayDanhSachLoaiTrangThai,
  ThemMoiLoaiTrangThai,
  SuaLoaiTrangThai,
  XoaLoaiTrangThai,
}
