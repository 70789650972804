export const ROUTE_NAME = {
  BAO_CAO_THONG_KE: {
    NAME: '/ds-bao-cao-thong-ke/',
    DANH_SACH_THONG_KE: '/danh-sach-thong-ke',
    NHAP_LIEU_THONG_KE: '/nhap-lieu-thong-ke',
    CHI_TIET: '/chi-tiet-ho-so',
  },
}

export const MENU_ROUTE_NAME = {
  NAME: 'Báo cáo thống kê',
  DANH_SACH_THONG_KE: 'Danh sách báo cáo thống kê',
  DANH_SACH_NHAP_LIEU_THONG_KE: 'Danh sách nhập liệu thống kê',
  NHAP_LIEU_THONG_KE: 'Nhập liệu báo cáo thống kê',
  CHI_TIET_HO_SO: 'Chi tiết hồ sơ',
}

export const MONTHS = [
  {
    text: 'Tháng 1',
    value: 0,
  },
  {
    text: 'Tháng 2',
    value: 1,
  },
  {
    text: 'Tháng 3',
    value: 2,
  },
  {
    text: 'Tháng 4',
    value: 3,
  },
  {
    text: 'Tháng 5',
    value: 4,
  },
  {
    text: 'Tháng 6',
    value: 5,
  },
  {
    text: 'Tháng 7',
    value: 6,
  },
  {
    text: 'Tháng 8',
    value: 7,
  },
  {
    text: 'Tháng 9',
    value: 8,
  },
  {
    text: 'Tháng 10',
    value: 9,
  },
  {
    text: 'Tháng 11',
    value: 10,
  },
  {
    text: 'Tháng 12',
    value: 11,
  },
]

export const YEARS = () => {
  const currentYear = new Date()
  const result = []
  for (let i = 0; i < 5; i += 1) {
    const year = currentYear.getFullYear() - i
    result.push({
      name: `Năm ${year}`,
      id: year,
    })
  }
  return result
}

export const QUARTERS = [
  {
    text: 'Quý I',
    value: 0,
  },
  {
    text: 'Quý II',
    value: 3,
  },
  {
    text: 'Quý III',
    value: 6,
  },
  {
    text: 'Quý IV',
    value: 9,
  },
]
