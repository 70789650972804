<template>
  <div>
    <multiselect
      v-model="value"
      :multiple="true"
      :options="options"
      :group-values="isGroup ? 'child' : null"
      :group-label="isGroup ? 'label' : null"
      :group-select="isGroup"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :disabled="getDisabled"
      label="label"
      track-by="label"
      select-label="enter để chọn"
      select-group-label="enter để chọn nhóm"
      selected-label="đã chọn"
      deselect-label="enter để bỏ chọn"
      @input="emitListener"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  data() {
    return {
      value: null,
      options: this.$parent.options,
      isGroup: this.$parent.$attrs.group,
    }
  },
  computed: {
    getDisabled() {
      return this.$parent.$attrs.disabled || false
    },
  },
  methods: {
    emitListener() {
      this.$emit('control-value', this.value.map(h => JSON.stringify(h)).toString())
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
