import axios from 'axios'
import _isNil from 'lodash/isNil'
import { DOMAIN, API_VERSION } from '@/constants/constants'
import {
  LOCAL_STORAGE_KEY,
  getValueFromStorageByKey, removeValueToStorageByKey,
} from '@/utils/local-storage'
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle

function buildQueries(params) {
  let requestString = ''
  Object.keys(params).forEach(key => {
    if (!_isNil(params[key]) && params[key] !== '') {
      requestString += `${key}=${params[key]}&`
    }
  })
  return requestString.slice(0, -1)
}

const HTTP = axios.create({
  baseURL: `${DOMAIN}${API_VERSION}`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
})

HTTP.interceptors.request.use(
  config => {
    // eslint-disable-next-line no-unused-expressions
    const token = getValueFromStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    Vue.prototype.$isLoading = true
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    Vue.prototype.$isLoading = false
    Promise.reject(error)
  },
)

HTTP.interceptors.response.use(
  response => {
    Vue.prototype.$isLoading = false
    return response
  },
  error => {
    Vue.prototype.$isLoading = false
    const { response, config } = error
    const originalRequest = config
    const accessToken = localStorage.getItem('accessToken')

    if (!response) {
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.PERMISSIONS)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
      removeValueToStorageByKey(LOCAL_STORAGE_KEY.IS_LOGIN)
      window.location.replace(`${window.location.origin}/login`)
      return Promise.reject(error)
    }

    const currentTime = Math.floor(Date.now() / 1000)
    const expirationTime = JSON.parse(atob(accessToken.split('.')[1])).exp

    // eslint-disable-next-line no-underscore-dangle
    if (response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true

      if (currentTime > expirationTime) {
        removeValueToStorageByKey(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
        removeValueToStorageByKey(LOCAL_STORAGE_KEY.PERMISSIONS)
        removeValueToStorageByKey(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
        removeValueToStorageByKey(LOCAL_STORAGE_KEY.IS_LOGIN)
        window.location.replace(`${window.location.origin}/login`)
        return axios(originalRequest)
      }
    }

    return Promise.reject(error)
  },
)

export { HTTP, buildQueries }
