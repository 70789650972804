// import { ROUTE_NAME as ROUTE_NAME_TC } from '@/modules/trang-chu/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_TNHS } from '@/modules/tiep-nhan-ho-so/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_XLHS } from '@/modules/xu-ly-ho-so/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_TKQ } from '@/modules/tra-ket-qua/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_NKQ } from '@/modules/nhan-ket-qua/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_TRA_CUU } from '@/modules/tra-cuu/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_MC } from '@/modules/mot-cua/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_QLQT } from '@/modules/quan-ly-quy-trinh/constants/constants'
// import { ROUTE_NAME as ROUTE_NAME_BCTK } from '@/modules/bao-cao-thong-ke/constants/constants'

export const DOMAIN = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : 'http://192.168.20.108:8686'

export const API_VERSION = process.env.VUE_APP_API_VERSION ? process.env.VUE_APP_API_VERSION : '/api/v1/'

export const ROUTE_NAME = {
  TRANG_CHU: '/trang-chu',
  DANG_NHAP: '/dang-nhap',
  CHUA_CO_QUYEN: '/chua-co-quyen',
  QUAN_TRI_HE_THONG: {
    NAME: '/quan-tri-he-thong/',
    VAI_TRO: 'vai-tro',
    XEM_CHI_TIET_VAI_TRO: 'vai-tro/:id/xem',
    CAP_NHAT_VAI_TRO: 'vai-tro/:id',
    NGUOI_DUNG: 'nguoi-dung',
    XEM_CHI_TIET_NGUOI_DUNG: 'nguoi-dung/:id/xem',
    CAP_NHAT_NGUOI_DUNG: 'nguoi-dung/:id',
  },
}

export const MENU_ROUTE_NAME = {
  TRANG_CHU: 'Dashboard',
  QUAN_TRI_HE_THONG: {
    NAME: 'Quản trị hệ thống',
    VAI_TRO: 'Quản lý vai trò',
    XEM_VAI_TRO: 'Xem thông tin vai trò',
    CAP_NHAT_VAI_TRO: 'Cập nhật vai trò',
    NGUOI_DUNG: 'Quản lý người dùng',
    XEM_NGUOI_DUNG: 'Xem thông tin người dùng',
    CAP_NHAT_NGUOI_DUNG: 'Cập nhật người dùng',
  },
}

export const PERMISSION_NAME = {
  DANH_MUC_KY_SO: 'KySo_DanhSach',
  THOI_GIAN_LAM_VIEC: {
    DANH_SACH: 'ThoiGianLamViec_DanhSach',
    CAP_NHAT_LICH_LAM_VIEC: 'ThoiGianLamViec_CapNhatLichLamViec',
    CAP_NHAT_NGAY_NGHI: 'ThoiGianLamViec_CapNhatNgayNghi',
    THEM_NGAY_NGHI: 'ThoiGianLamViec_ThemNgayNghi',
    XOA: 'ThoiGianLamViec_Xoa',
  },
  LOAI_TRANG_THAI: {
    DANH_SACH: 'LoaiTrangThai_DanhSach',
    THEM_MOI: 'LoaiTrangThai_ThemMoi',
    SUA: 'LoaiTrangThai_Sua',
    XOA: 'LoaiTrangThai_Xoa',
    COMBOBOX: 'LoaiTrangThai_Combobox',
  },
  TRANG_THAI: {
    DANH_SACH: 'TrangThai_DanhSach',
    THEM_MOI: 'TrangThai_ThemMoi',
    SUA: 'TrangThai_Sua',
    XOA: 'TrangThai_Xoa',
    COMBOBOX: 'TrangThai_Combobox',
  },
  NHOM_PHIEU: {
    DANH_SACH: 'NhomPhieu_DanhSach',
    THEM_MOI: 'NhomPhieu_ThemMoi',
    SUA: 'NhomPhieu_Sua',
    XOA: 'NhomPhieu_Xoa',
  },
  MAU_PHIEU: {
    DANH_SACH: 'MauPhieu_DanhSach',
    THEM_MOI: 'MauPhieu_ThemMoi',
    SUA: 'MauPhieu_Sua',
    XOA: 'MauPhieu_Xoa',
    PHAN_QUYEN: 'MauPhieu_PhanQuyen',
    COMBOBOX: 'NhomMauPhieu_Combobox',
  },
  DAN_TOC: {
    DANH_SACH: 'DanToc_DanhSach',
    THEM_MOI: 'DanToc_ThemMoi',
    SUA: 'DanToc_Sua',
    XOA: 'DanToc_Xoa',
  },
  LINH_VUC: {
    DANH_SACH: 'LinhVuc_DanhSach',
    THEM_MOI: 'LinhVuc_ThemMoi',
    SUA: 'LinhVuc_Sua',
    XOA: 'LinhVuc_Xoa',
    COMBOBOX: 'LinhVuc_Combobox',
  },
  QUOC_TICH: {
    DANH_SACH: 'QuocTich_DanhSach',
    THEM_MOI: 'QuocTich_ThemMoi',
    SUA: 'QuocTich_Sua',
    XOA: 'QuocTich_Xoa',
    COMBOBOX: 'QuocTich_Combobox',
  },
  BAO_CAO: {
    DANH_SACH: 'BaoCao_DanhSach',
    THEM_MOI: 'BaoCao_ThemMoi',
    SUA: 'BaoCao_Sua',
    XOA: 'BaoCao_Xoa',
    COMBOBOX: 'BaoCao_Combobox',
  },
  PHUONG_THUC_DANH_GIA: {
    DANH_SACH: 'PhuongThucDanhGia_DanhSach',
    THEM_MOI: 'PhuongThucDanhGia_ThemMoi',
    SUA: 'PhuongThucDanhGia_Sua',
    XOA: 'PhuongThucDanhGia_Xoa',
    COMBOBOX: 'PhuongThucDanhGia_Combobox',
  },
  NHOM_TIEU_CHI: {
    DANH_SACH: 'NhomTieuChi_DanhSach',
    THEM_MOI: 'NhomTieuChi_ThemMoi',
    SUA: 'NhomTieuChi_Sua',
    XOA: 'NhomTieuChi_Xoa',
    COMBOBOX: 'NhomTieuChi_Combobox',
  },
  TIEU_CHI_DANH_GIA: {
    DANH_SACH: 'TieuChiDanhGia_DanhSach',
    THEM_MOI: 'TieuChiDanhGia_ThemMoi',
    SUA: 'TieuChiDanhGia_Sua',
    XOA: 'TieuChiDanhGia_Xoa',
    COMBOBOX: '',
  },
  THUOC_TINH_TIEU_CHI_DANH_GIA: {
    DANH_SACH: 'ThuocTinhTieuChiDanhGia_DanhSach',
    THEM_MOI: 'ThuocTinhTieuChiDanhGia_ThemMoi',
    SUA: 'ThuocTinhTieuChiDanhGia_Sua',
    XOA: 'ThuocTinhTieuChiDanhGia_Xoa',
    COMBOBOX: 'TieuChiDanhGia_Combobox',
  },
  GIAY_TO: {
    DANH_SACH: 'GiayTo_DanhSach',
    THEM_MOI: 'GiayTo_ThemMoi',
    SUA: 'GiayTo_Sua',
    XOA: 'GiayTo_Xoa',
    COMBOBOX: 'GiayTo_Combobox',
  },
  HANH_DONG: {
    DANH_SACH: 'HanhDong_DanhSach',
    THEM_MOI: 'HanhDong_ThemMoi',
    SUA: 'HanhDong_Sua',
    XOA: 'HanhDong_Xoa',
    COMBOBOX: 'HanhDong_Combobox',
  },
  BIEU_MAU: {
    DANH_SACH: 'BieuMau_DanhSach',
    THEM_MOI: 'BieuMau_ThemMoi',
    SUA: 'BieuMau_Sua',
    XOA: 'BieuMau_Xoa',
    COMBOBOX: 'BieuMau_Combobox',
  },
  TTHC: {
    DANH_SACH: 'TTHC_DanhSach',
    THEM_MOI: 'TTHC_ThemMoi',
    SUA: 'TTHC_Sua',
    XOA: 'TTHC_Xoa',
    COMBOBOX: 'TTHC_Combobox',
  },
  COMMON_DON_VI_COMBOBOX: 'Common_DonVi_Combobox',
  TTHC_MAU_HO_SO: {
    DANH_SACH: 'TTHC_MauHoSo_DanhSach',
    THEM_MOI: 'TTHC_MauHoSo_ThemMoi',
    SUA: 'TTHC_MauHoSo_Sua',
    XOA: 'TTHC_MauHoSo_Xoa',
    COMBOBOX: '',
  },
  TTHC_BIEU_MAU: {
    DANH_SACH: 'TTHC_BieuMau_DanhSach',
    THEM_MOI: 'TTHC_BieuMau_ThemMoi',
    SUA: 'TTHC_BieuMau_Sua',
    XOA: 'TTHC_BieuMau_Xoa',
    COMBOBOX: '',
  },
  QUY_TRINH_DONG: {
    DANH_SACH: 'QuyTrinhDong_DanhSach',
    THEM_MOI: 'QuyTrinhDong_ThemMoi',
    SUA: 'QuyTrinhDong_Sua',
    XOA: 'QuyTrinhDong_Xoa',
    COMBOBOX: 'QuyTrinhDong_Combobox',
  },
  CAU_HINH_QUY_TRINH_DONG: {
    DANH_SACH: 'CauHinhQuyTrinhDong_DanhSach',
    THEM_MOI: 'CauHinhQuyTrinhDong_ThemMoi',
    SUA: 'CauHinhQuyTrinhDong_Sua',
    XOA: 'CauHinhQuyTrinhDong_Xoa',
    COMBOBOX: '',
  },
  HOI_DAP: {
    DANH_SACH: 'HoiDap_DanhSach',
    THEM_MOI: 'HoiDap_ThemMoi',
    SUA: 'HoiDap_Sua',
    XOA: 'HoiDap_Xoa',
    COMBOBOX: '',
    CHI_TIET: 'HoiDap_XemChiTiet',
  },
  HDSD: {
    DANH_SACH: 'HDSD_DanhSach',
    THEM_MOI: 'HDSD_ThemMoi',
    SUA: 'HDSD_Sua',
    XOA: 'HDSD_Xoa',
    COMBOBOX: '',
  },
  TRA_LOG: {
    DANH_SACH: 'TraLog_DanhSach',
    THEM_MOI: '',
    SUA: '',
    XOA: '',
    COMBOBOX: '',
  },
  CAU_HINH_THONG_BAO: {
    DANH_SACH: 'CauHinhThongBao_DanhSach',
    THEM_MOI: 'CauHinhThongBao_ThemMoi',
    SUA: 'CauHinhThongBao_Sua',
    XOA: 'CauHinhThongBao_Xoa',
    COMBOBOX: '',
  },
  CAU_HINH_CONG_THANH_TOAN: {
    DANH_SACH: 'CauHinhCongThanhToan_DanhSach',
    THEM_MOI: 'CauHinhCongThanhToan_ThemMoi',
    SUA: 'CauHinhCongThanhToan_Sua',
    XOA: 'CauHinhCongThanhToan_Xoa',
    COMBOBOX: '',
  },
  CAU_HINH_THAM_SO_HE_THONG: {
    DANH_SACH: 'CauHinhThamSoHeThong_DanhSach',
    SUA: 'CauHinhThamSoHeThong_Sua',
  },
  COMMON_VAI_TRO_COMBOBOX: 'Common_VaiTro_Combobox',
  PHAN_QUYEN_TTHC: {
    DANH_SACH: 'PhanQuyenTTHC_DanhSach',
    THEM_MOI: 'PhanQuyenTTHC_ThemMoi',
    SUA: 'PhanQuyenTTHC_Sua',
    XOA: 'PhanQuyenTTHC_Xoa',
    COMBOBOX: '',
  },
  PHAN_QUYEN_LINH_VUC: {
    DANH_SACH: 'PhanQuyenLinhVuc_DanhSach',
    THEM_MOI: 'PhanQuyenLinhVuc_ThemMoi',
    SUA: 'PhanQuyenLinhVuc_Sua',
    XOA: 'PhanQuyenLinhVuc_Xoa',
    COMBOBOX: '',
  },
  PHAN_QUYEN_BAO_CAO_LINH_VUC: {
    DANH_SACH: 'PhanQuyenBaoCaoLinhVuc_DanhSach',
    THEM_MOI: 'PhanQuyenBaoCaoLinhVuc_ThemMoi',
    SUA: 'PhanQuyenBaoCaoLinhVuc_Sua',
    XOA: 'PhanQuyenBaoCaoLinhVuc_Xoa',
    COMBOBOX: '',
  },
  PHAN_QUYEN_BAO_CAO_VAI_TRO: {
    DANH_SACH: 'PhanQuyenBaoCaoVaiTro_DanhSach',
    THEM_MOI: 'PhanQuyenBaoCaoVaiTro_ThemMoi',
    SUA: 'PhanQuyenBaoCaoVaiTro_Sua',
    XOA: 'PhanQuyenBaoCaoVaiTro_Xoa',
    COMBOBOX: '',
  },
  NHAT_KY_HE_THONG: {
    DANH_SACH: 'NhatKyHeThong_DanhSach',
  },
  TRANG_CHU: 'TrangChu',
  HSTNTRUC_TIEP: {
    CHI_TIET_THONG_TIN_CHUNG: 'HSTNTrucTiep_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HSTNTrucTiep_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HSTNTrucTiep_ChiTiet_TienTrinhXuLy',
    DANH_SACH: 'HSTNTrucTiep_DanhSach',
    SUA: 'HSTNTrucTiep_Sua',
    XOA: 'HSTNTrucTiep_Xoa',
  },
  HSTNTRUC_TUYEN: {
    CHI_TIET_THONG_TIN_CHUNG: 'HSTNTrucTuyen_TrucTuyen_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HSTNTrucTuyen_TrucTuyen_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HSTNTrucTuyen_TrucTuyen_ChiTiet_TienTrinhXuLy',
    DANH_SACH: 'HSTNTrucTuyen_TrucTuyen_DanhSach',
    TIEP_NHAN: 'HSTNTrucTuyen_TrucTuyen_TiepNhan',
    YEU_CAU_BO_SUNG: 'HSTNTrucTuyen_TrucTuyen_YeuCauBoSung',
    TU_CHOI: 'HSTNTrucTuyen_TrucTuyen_TuChoi',
    BO_SUNG_DANH_SACH: 'HSTNTrucTuyen_TrucTuyenBoSung_DanhSach',
    BO_SUNG_TIEP_NHAN: 'HSTNTrucTuyen_TrucTuyenBoSung_TiepNhan',
    BO_SUNG_YEU_CAU_BO_SUNG: 'HSTNTrucTuyen_TrucTuyenBoSung_YeuCauBoSung',
    BO_SUNG_YEU_CAU_TU_CHOI: 'HSTNTrucTuyen_TrucTuyenBoSung_TuChoi',
    BO_SUNG_CHI_TIET_THONG_TIN_CHUNG: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_ThongTinChung',
    BO_SUNG_CHI_TIET_XU_LY_HO_SO: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_XuLyHoSo',
    BO_SUNG_CHI_TIET_TIEN_TRINH_XU_LY: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_TienTrinhXuLy',
  },
  HSTNTRUC_TUYEN_BO_SUNG: {
    DANH_SACH: 'HSTNTrucTuyen_TrucTuyenBoSung_DanhSach',
    TIEP_NHAN: 'HSTNTrucTuyen_TrucTuyenBoSung_TiepNhan',
    YEU_CAU_BO_SUNG: 'HSTNTrucTuyen_TrucTuyenBoSung_YeuCauBoSung',
    TU_CHOI: 'HSTNTrucTuyen_TrucTuyenBoSung_TuChoi',
    CHI_TIET_THONG_TIN_CHUNG: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HSTNTrucTuyen_TrucTuyenBoSung_ChiTiet_TienTrinhXuLy',
  },
  TIEP_NHAN_BO_SUNG: {
    DANH_SACH: 'TiepNhanBoSung_DanhSach',
    TIEP_NHAN: 'TiepNhanBoSung_TiepNhan',
    CHI_TIET: 'TiepNhanBoSung_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'TiepNhanBoSung_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'TiepNhanBoSung_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'TiepNhanBoSung_ChiTiet_TienTrinhXuLy',
    IN: 'TiepNhanBoSung_In',
  },
  BAN_GIAO_HO_SO: {
    DANH_SACH: 'BanGiaoHoSo_DanhSach',
    BAN_GIAO: 'BanGiaoHoSo_BanGiao',
    CHI_TIET: 'BanGiaoHoSo_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'BanGiaoHoSo_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'BanGiaoHoSo_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'BanGiaoHoSo_ChiTiet_TienTrinhXuLy',
    IN: 'BanGiaoHoSo_In',
    SUA: 'BanGiaoHoSo_Sua',
  },
  YEU_CAU_BO_SUNG: {
    DANH_SACH: 'YeuCauBoSung_DanhSach',
    TIEP_NHAN: 'YeuCauBoSung_TiepNhan',
    CHI_TIET: 'YeuCauBoSung_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'YeuCauBoSung_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'YeuCauBoSung_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'YeuCauBoSung_ChiTiet_TienTrinhXuLy',
    IN: 'YeuCauBoSung_In',
  },
  HO_SO_DANG_XU_LY: {
    DANH_SACH: 'HoSoDangXuLy_DanhSach',
    XU_LY_NHIEU_HO_SO: 'HoSoDangXuLy_XuLyNhieuHoSo',
    CHI_TIET: 'HoSoDangXuLy_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'HoSoDangXuLy_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HoSoDangXuLy_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HoSoDangXuLy_ChiTiet_TienTrinhXuLy',
    IN: 'HoSoDangXuLy_In',
  },
  PHAN_XU_LY_HO_SO: {
    DANH_SACH: 'PhanXuLyHoSo_DanhSach',
    XU_LY_NHIEU_HO_SO: 'PhanXuLyHoSo_XuLyNhieuHoSo',
    CHI_TIET: 'PhanXuLyHoSo_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'PhanXuLyHoSo_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'PhanXuLyHoSo_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'PhanXuLyHoSo_ChiTiet_TienTrinhXuLy',
    IN: 'PhanXuLyHoSo_In',
  },
  DUYET_DE_XUAT: {
    DANH_SACH: 'DuyetDeXuat_DanhSach',
    XU_LY_NHIEU_HO_SO: 'DuyetDeXuat_XuLyNhieuHoSo',
    CHI_TIET: 'DuyetDeXuat_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'DuyetDeXuat_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'DuyetDeXuat_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'DuyetDeXuat_ChiTiet_TienTrinhXuLy',
    IN: 'DuyetDeXuat_In',
  },
  DUYET_KY_HO_SO: {
    DANH_SACH: 'DuyetKyHoSo_DanhSach',
    XU_LY_NHIEU_HO_SO: 'DuyetKyHoSo_XuLyNhieuHoSo',
    CHI_TIET: 'DuyetKyHoSo_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'DuyetKyHoSo_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'DuyetKyHoSo_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'DuyetKyHoSo_ChiTiet_TienTrinhXuLy',
    IN: 'DuyetKyHoSo_In',
  },
  DUYET_GIA_HAN_HO_SO: {
    DANH_SACH: 'DuyetGiaHanHoSo_DanhSach',
    DUYET_NHIEU_HO_SO: 'DuyetGiaHanHoSo_DuyetNhieuHoSo',
    CHI_TIET: 'DuyetGiaHanHoSo_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'DuyetGiaHanHoSo_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'DuyetGiaHanHoSo_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'DuyetGiaHanHoSo_ChiTiet_TienTrinhXuLy',
    IN: 'DuyetGiaHanHoSo_In',
  },
  HO_SO_CHO_THU_PHI: {
    DANH_SACH: 'HoSoChoThuPhi_DanhSach',
    THU_TIEN: 'HoSoChoThuPhi_ThuTien',
    CHI_TIET_THONG_TIN_CHUNG: 'HoSoChoThuPhi_ChiTiet_ThongTinChung',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HoSoChoThuPhi_ChiTiet_TienTrinhXuLy',
  },
  HO_SO_CAN_THU_PHI: {
    DANH_SACH: 'HoSoCanThuPhi_DanhSach',
    CAP_NHAT_THONG_TIN_THU_PHI: 'HoSoCanThuPhi_CapNhatThongTinThuPhi',
    GUI_YEU_CAU_THU_PHI: 'HoSoCanThuPhi_GuiYeuCauThuPhi',
    CHI_TIET_THONG_TIN_CHUNG: 'HoSoCanThuPhi_ChiTiet_ThongTinChung',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HoSoCanThuPhi_ChiTiet_TienTrinhXuLy',
  },
  HO_SO_GIAI_QUYET: {
    DANH_SACH: 'HoSoGiaiQuyet_DanhSach',
    TRA_KET_QUA_NHIEU_HS: 'HoSoGiaiQuyet_TraKetQuaNhieuHS',
    CHI_TIET: 'HoSoGiaiQuyet_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'HoSoGiaiQuyet_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HoSoGiaiQuyet_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HoSoGiaiQuyet_ChiTiet_TienTrinhXuLy',
    IN: 'HoSoGiaiQuyet_In',
  },
  HO_SO_TU_CHOI_GIAI_QUYET: {
    DANH_SACH: 'HoSoTuChoiGiaiQuyet_DanhSach',
    TRA_KET_QUA_NHIEU_HS: 'HoSoTuChoiGiaiQuyet_TraKetQuaNhieuHS',
    CHI_TIET: 'HoSoTuChoiGiaiQuyet_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'HoSoTuChoiGiaiQuyet_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'HoSoTuChoiGiaiQuyet_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'HoSoTuChoiGiaiQuyet_ChiTiet_TienTrinhXuLy',
    IN: 'HoSoTuChoiGiaiQuyet_In',
  },
  CAU_HINH_DANH_GIA: {
    DANH_SACH: 'CauHinhDanhGia_DanhSach',
  },
  NHAN_KET_QUA_HO_SO: {
    DANH_SACH: 'NhanKetQuaHoSo_DanhSach',
    TRA_KET_QUA_NHIEU_HS: 'NhanKetQuaHoSo_TraKetQuaNhieuHS',
    CHI_TIET: 'NhanKetQuaHoSo_ChiTiet',
    CHI_TIET_THONG_TIN_CHUNG: 'NhanKetQuaHoSo_ChiTiet_ThongTinChung',
    CHI_TIET_XU_LY_HO_SO: 'NhanKetQuaHoSo_ChiTiet_XuLyHoSo',
    CHI_TIET_TIEN_TRINH_XU_LY: 'NhanKetQuaHoSo_ChiTiet_TienTrinhXuLy',
    IN: 'NhanKetQuaHoSo_In',
  },
  TRA_CUU: 'TraCuu',
  BAO_CAO_THONG_KE: 'BaoCaoThongKe',
  DASBOARD: {
    CHO_TIEP_NHAN: 'TrangChu_ChoTiepNhan',
    MOI_TIEP_NHAN: 'TrangChu_MoiTiepNhan',
    DANG_XU_LY: 'TrangChu_DangXuLy',
    CHO_KY_DUYET: 'TrangChu_ChoKyDuyet',
    DA_CO_KET_QUA: 'TrangChu_DaCoKetQua',
    CHO_TRA_KET_QUA: 'TrangChu_ChoTraKetQua',
    TY_LE_HSTTHC_THEO_HINH_THUC_NOP: 'TrangChu_TyLeHSTTHCTheoHinhThucNop',
    TINH_HINH_THU_PHI_LE_PHI: 'TrangChu_TinhHinhThuPhiLePhi',
    TINH_HINH_GIAI_QUYET_HO_SO: 'TrangChu_TinhHinhGiaiQuyetHoSo',
    KET_QUA_XU_LY_HO_SO: 'TrangChu_KetQuaXuLyHoSo',
  },
}

export const ROUTER_PERMISSION = [
  // TRANG CHỦ
  {
    path: '/trang-chu',
    permission: PERMISSION_NAME.TRANG_CHU,
  },
  // TIẾP NHẬN HỒ SƠ
  {
    path: '/tiep-nhan-ho-so/truc-tiep/tiep-nhan-ho-so-truc-tiep',
    permission: PERMISSION_NAME.HSTNTRUC_TIEP.DANH_SACH,
  },
  {
    path: '/tiep-nhan-ho-so/hs-truc-tuyen/tiep-nhan-ho-so-truc-tuyen',
    permission: PERMISSION_NAME.HSTNTRUC_TUYEN.DANH_SACH,
  },
  {
    path: '/tiep-nhan-ho-so/tn-bo-sung/tiep-nhan-ho-so-bo-sung',
    permission: PERMISSION_NAME.TIEP_NHAN_BO_SUNG.DANH_SACH,
  },
  // XỬ LÝ HỒ SƠ
  {
    path: '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs',
    permission: PERMISSION_NAME.BAN_GIAO_HO_SO.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/yeu-cau-bs/yeu-cau-bs',
    permission: PERMISSION_NAME.YEU_CAU_BO_SUNG.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/ho-so-dang-xu-ly/ho-so-dang-xu-ly',
    permission: PERMISSION_NAME.HO_SO_DANG_XU_LY.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/phan-xu-ly-ho-so/phan-xu-ly-ho-so',
    permission: PERMISSION_NAME.PHAN_XU_LY_HO_SO.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/duyet-de-xuat/duyet-de-xuat',
    permission: PERMISSION_NAME.DUYET_DE_XUAT.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/duyet-ky-ho-so/duyet-ky-ho-so',
    permission: PERMISSION_NAME.DUYET_KY_HO_SO.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/duyet-gia-han',
    permission: PERMISSION_NAME.DUYET_GIA_HAN_HO_SO.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/can-thu-phi-le-phi/can-thu-phi-le-phi',
    permission: PERMISSION_NAME.HO_SO_CAN_THU_PHI.DANH_SACH,
  },
  {
    path: '/xu-ly-ho-so/cho-thu-phi-le-phi/cho-thu-phi-le-phi',
    permission: PERMISSION_NAME.HO_SO_CHO_THU_PHI.DANH_SACH,
  },
  // TRẢ KẾT QUẢ
  {
    path: '/tra-ket-qua/hs-giai-quyet/hs-giai-quyet',
    permission: PERMISSION_NAME.HO_SO_GIAI_QUYET.DANH_SACH,
  },
  {
    path: '/tra-ket-qua/hs-khong-giai-quyet/hs-khong-giai-quyet',
    permission: PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.DANH_SACH,
  },
  // NHẬN KẾT QUẢ
  {
    path: '/nhan-ket-qua/nhan-ket-qua-hs/nhan-ket-qua',
    permission: PERMISSION_NAME.NHAN_KET_QUA_HO_SO.DANH_SACH,
  },
  // TRA CỨU
  {
    path: '/tra-cuu/tra-cuu-ho-so/tra-cuu-ho-so',
    permission: PERMISSION_NAME.TRA_CUU,
  },
  // QUY TRÌNH
  {
    path: '/quan-ly-quy-trinh/quan-ly-luong-xu-ly/danh-sach',
    permission: PERMISSION_NAME.QUY_TRINH_DONG.DANH_SACH,
  },
  {
    path: '/quan-ly-quy-trinh/cau-hinh-quy-trinh',
    permission: PERMISSION_NAME.CAU_HINH_QUY_TRINH_DONG.DANH_SACH,
  },
  // THỦ TỤC HÀNH CHÍNH
  {
    path: '/thu-tuc-hanh-chinh/ds-tthc',
    permission: PERMISSION_NAME.TTHC.DANH_SACH,
  },
  // DANH MỤC
  {
    path: '/danh-muc/linh-vuc',
    permission: PERMISSION_NAME.LINH_VUC.DANH_SACH,
  },
  {
    path: '/danh-muc/giay-to',
    permission: PERMISSION_NAME.GIAY_TO.DANH_SACH,
  },
  {
    path: '/quan-tri-he-thong/danh-sach-form',
    permission: PERMISSION_NAME.BIEU_MAU.DANH_SACH,
  },
  {
    path: '/danh-muc/ttlvtt',
    permission: PERMISSION_NAME.THOI_GIAN_LAM_VIEC.DANH_SACH,
  },
  {
    path: '/danh-muc/loai-trang-thai',
    permission: PERMISSION_NAME.LOAI_TRANG_THAI.DANH_SACH,
  },
  {
    path: '/danh-muc/trang-thai',
    permission: PERMISSION_NAME.TRANG_THAI.DANH_SACH,
  },
  {
    path: '/danh-muc/nhom-phieu',
    permission: PERMISSION_NAME.NHOM_PHIEU.DANH_SACH,
  },
  {
    path: '/danh-muc/mau-phieu',
    permission: PERMISSION_NAME.MAU_PHIEU.DANH_SACH,
  },
  {
    path: '/danh-muc/dan-toc',
    permission: PERMISSION_NAME.DAN_TOC.DANH_SACH,
  },
  {
    path: '/danh-muc/quoc-tich',
    permission: PERMISSION_NAME.QUOC_TICH.DANH_SACH,
  },
  {
    path: '/danh-muc/bao-cao',
    permission: PERMISSION_NAME.BAO_CAO,
  },
  {
    path: '/phan-quyen/hanh-dong',
    permission: PERMISSION_NAME.HANH_DONG.DANH_SACH,
  },
  {
    path: '/phan-quyen/hdsd',
    permission: PERMISSION_NAME.HDSD.DANH_SACH,
  },
  // TIỆN ÍCH
  {
    path: '/tien-ich/cong-thanh-toan',
    permission: PERMISSION_NAME.CAU_HINH_CONG_THANH_TOAN.DANH_SACH,
  },
  {
    path: '/tien-ich/tham-so-he-thong',
    permission: PERMISSION_NAME.CAU_HINH_THAM_SO_HE_THONG.DANH_SACH,
  },
  // ĐÁNH GIÁ, KHẢO SÁT
  {
    path: '/danh-muc/tien-ich/hoi-dap',
    permission: PERMISSION_NAME.HOI_DAP.DANH_SACH,
  },
  {
    path: '/danh-gia-khao-sat/nhom-tieu-chi',
    permission: PERMISSION_NAME.NHOM_TIEU_CHI.DANH_SACH,
  },
  {
    path: '/danh-gia-khao-sat/tieu-chi-danh-gia',
    permission: PERMISSION_NAME.CAU_HINH_DANH_GIA.DANH_SACH,
  },
  // PHÂN QUYỀN
  {
    path: '/phan-quyen/bao-cao-tk/pqbclv',
    permission: PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_LINH_VUC.DANH_SACH,
  },
  {
    path: '/phan-quyen/bao-cao-tk/pqbcdv',
    permission: PERMISSION_NAME.PHAN_QUYEN_BAO_CAO_VAI_TRO.DANH_SACH,
  },
  {
    path: '/phan-quyen/pqtthcnnd',
    permission: PERMISSION_NAME.PHAN_QUYEN_TTHC.DANH_SACH,
  },
  // NHẬT KÝ
  {
    path: '/phan-quyen/nkht',
    permission: PERMISSION_NAME.NHAT_KY_HE_THONG,
  },
  // BÁO CÁO THỐNG KÊ
  {
    path: '/danh-sach-thong-ke',
    permission: PERMISSION_NAME.BAO_CAO_THONG_KE,
  },
]

export const PERMISSION_ROLE = {
  XEM: 'Xem',
  CAP_NHAT: 'CapNhat',
}

export const VAI_TRO_TAB = {
  THONG_TIN_VAI_TRO: 'thong-tin-nhom',
  NGUOI_DUNG: 'nguoi-dung',
  QUYEN: 'quyen',
}

export const NGUOI_DUNG_TAB = {
  THONG_TIN_NGUOI_DUNG: 'thong-tin-nguoi-dung',
  VAI_TRO: 'vai-tro',
  QUYEN: 'quyen',
}

export const NO_RESULTS_TEXT = 'Không tìm thấy kết quả'

export const TREE_SELECT_PLACEHOLDER = 'Chọn đơn vị'

export const MA_TRA_LOI = {
  THANH_CONG: 1,
  THAT_BAI: 0,
}

export const BANG_NGUOI_DUNG = {
  checkbox: '',
  stt: 'STT',
  name: 'Họ và tên',
  userName: 'Tên đăng nhập',
  groups: 'Vai trò',
  thaoTac: 'Thao tác',
  trangThai: 'Trạng thái',
}

export const BANG_VAI_TRO = {
  checkbox: '',
  stt: 'STT',
  name: 'Tên vai trò',
  description: 'Mô tả vai trò',
  displayName: 'Tên hiển thị vai trò',
  thaoTac: 'Thao tác',
}

export const BANG_DANH_SACH_QUYEN = {
  name: 'Mã quyền',
  displayName: 'Tên quyền',
  thaoTac: 'Thao tác',
}

export const BO_QUA_BUTTON_LABEL = 'Bỏ qua'
export const DONG_Y_BUTTON_LABEL = 'Đồng ý'

export const PER_PAGE_OPTIONS = [20, 25, 50, 100]
export const COLUMN_KY_SO = [
  {
    label: 'STT',
    thClass: 'text-center custom-style',
    tdClass: 'text-center',
    width: '50px',
    field: 'STT',
  },
  {
    label: 'Tài khoản',
    field: 'username',
    width: '200px',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Loại ký số',
    field: 'loaiKySo',
    width: '200px',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Nhà phát hành',
    field: 'nhaPhatHanh',
    width: 'auto',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Thông tin người ký',
    field: 'thongTinNguoiKy',
    width: '250px',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Số serial',
    field: 'serialNumber',
    width: '150px',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Hiệu lực',
    field: 'status',
    width: '150px',
    thClass: 'text-center',
    filterOptions: {
      enabled: true,
      placeholder: 'Tìm kiếm',
      filterValue: null,
    },
  },
  {
    label: 'Chức năng',
    thClass: 'text-center',
    tdClass: 'vertical-align-middle',
    field: 'actions',
    width: '130px',
  },
]
