import { HTTP } from '@/api/http-common'
import ENDPOINT from '@/modules/danh-muc/api/endpoints'

export function callApiDanhSachThoiGianLamViecTrongTuan(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN.DANH_SACH,
    method: 'GET',
    params: payload,
  })
}

export function callApiThemMoiThoiGianLamViecTrongTuan(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN.THEM_MOI,
    method: 'POST',
    data: payload,
  })
}

export function callApiSuaThoiGianLamViecTrongTuan(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN.SUA,
    method: 'POST',
    data: payload,
  })
}

export function callApiXoaThoiGianLamViecTrongTuan(payload) {
  return HTTP({
    url: ENDPOINT.DANH_MUC_DUNG_CHUNG.THOI_GIAN_LAM_VIEC_TRONG_TUAN.XOA,
    method: 'DELETE',
    data: payload,
  })
}
