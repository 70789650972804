export const ROUTE_NAME = {
  NAME: '/xu-ly-ho-so/',
  BAN_GIAO_HS: {
    NAME: 'ban-giao-hs',
    DS: 'ban-giao-hs',
    CT: 'ct-ban-giao-hs',
    SUA: 'sua-ban-giao-hs',
  },
  YEU_CAU_BS: {
    NAME: 'yeu-cau-bs',
    DS: 'yeu-cau-bs',
    CT: 'ct-yeu-cau-bs',
  },
  HS_DANG_XU_LY: {
    NAME: 'ho-so-dang-xu-ly',
    DS: 'ho-so-dang-xu-ly',
    CT: 'ct-ho-so-dang-xu-ly',
  },
  PHAN_XU_LY_HS: {
    NAME: 'phan-xu-ly-ho-so',
    DS: 'phan-xu-ly-ho-so',
    CT: 'ct-phan-xu-ly-ho-so',
  },
  DUYET_DE_XUAT: {
    NAME: 'duyet-de-xuat',
    DS: 'duyet-de-xuat',
    CT: 'ct-duyet-de-xuat',
  },
  DUYET_KY_HO_SO: {
    NAME: 'duyet-ky-ho-so',
    DS: 'duyet-ky-ho-so',
    CT: 'ct-duyet-ky-ho-so',
  },
  GIA_HAN_HS: {
    NAME: 'duyet-gia-han',
    HS_CAN_GH: {
      NAME: 'hs-can-gh',
      DS: 'ds-hs-can-gh',
    },
    HS_DA_GH: {
      NAME: 'hs-da-gh',
      DS: 'ds-hs-da-gh',
    },
  },
  CAN_THU_PHI_LE_PHI: {
    NAME: 'can-thu-phi-le-phi',
    DS_THU_PHI: 'can-thu-phi-le-phi',
    CHI_TIET: 'chi-tiet',
  },
  CHO_THU_PHI_LE_PHI: {
    NAME: 'cho-thu-phi-le-phi',
    DS_CHO_THU_PHI: 'cho-thu-phi-le-phi',
    CHI_TIET: 'chi-tiet',
  },
}

export const MENU_ROUTE_NAME = {
  NAME: 'Xử lý hồ sơ',
  BAN_GIAO_HS: 'Bàn giao hồ sơ',
  SUA: 'Cập nhật',
  YEU_CAU_BS: 'Yêu cầu bổ sung',
  CHI_TIET_HO_SO: 'Chi tiết hồ sơ',
  THEM_MOI: 'Thêm mới',
  HS_DANG_XU_LY: 'Hồ sơ đang xử lý',
  PHAN_XU_LY_HS: 'Phân xử lý hồ sơ',
  DUYET_DE_XUAT: 'Duyệt đề xuất',
  DUYET_KY_HO_SO: 'Duyệt ký hồ sơ',
  GIA_HAN_HS: {
    NAME: 'Duyệt gia hạn hồ sơ',
    HS_CAN_GH: {
      NAME: 'Hồ sơ cần gia hạn',
      DS: 'Danh sách',
    },
    HS_DA_GH: {
      NAME: 'Hồ sơ đã gia hạn',
      DS: 'Danh sách',
    },
  },
  CAN_THU_PHI_LE_PHI: {
    NAME: 'Hồ sơ cần thu phí, lệ phí',
  },
  CHO_THU_PHI_LE_PHI: {
    NAME: 'Hồ sơ chờ thu phí, lệ phí',
  },
}
