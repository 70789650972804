// eslint-disable-next-line import/no-cycle
import store from '@/store'
/**
 * Check the user has permission to access the control button, ... with permissionPolicy and permissionRole
 *
 * @param permissions = [{CapNhat: true, Permission: "QuanLyVaiTro", PermissionId: 12, Ten: "Quản lý vai trò", Xem: true}, {}]
 * @param permissionPolicy = "QuanLyVaiTro"
 * @param permissionRole CapNhat|Xem
 * @returns {*|boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export const hasPermission = permissionPolicy => {
  const { permissions } = store.state.common
  if (permissionPolicy && permissionPolicy.length > 0) {
    const filteredPermission = permissions
      && permissionPolicy.filter(item => permissions.map(ele => ele.policy).includes(item))
    return filteredPermission && filteredPermission.length > 0
  }
  return true
}
