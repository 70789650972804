export const ROUTE_NAME = {
  NAME: '/nhan-ket-qua/',
  HS_KET_QUA: {
    NAME: 'nhan-ket-qua-hs',
    DS_NHAN_HS: 'nhan-ket-qua',
    CT_NHAN_HS: 'nhan-kq-hs-chi-tiet',
  },
}

export const MENU_ROUTE_NAME = {
  NAME: 'Nhận kết quả',
  HS_KET_QUA: 'Nhận kết quả hồ sơ',
  CHI_TIET_HO_SO: 'Chi tiết hồ sơ',
}
