import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/bao-cao-thong-ke/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.BAO_CAO_THONG_KE.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.BAO_CAO_THONG_KE.NAME,
        name: ROUTE_NAME.BAO_CAO_THONG_KE.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.BAO_CAO_THONG_KE.DANH_SACH_THONG_KE,
            name: ROUTE_NAME.BAO_CAO_THONG_KE.DANH_SACH_THONG_KE,
            component: () => import('@/modules/bao-cao-thong-ke/components/pages/BaoCaoThongKe.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DANH_SACH_THONG_KE,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.BAO_CAO_THONG_KE],
            },
          },
          {
            path: ROUTE_NAME.BAO_CAO_THONG_KE.NHAP_LIEU_THONG_KE,
            name: ROUTE_NAME.BAO_CAO_THONG_KE.NHAP_LIEU_THONG_KE,
            component: () => import('@/modules/bao-cao-thong-ke/components/pages/NhapLieuThongKe.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DANH_SACH_NHAP_LIEU_THONG_KE,
                  active: true,
                },
              ],
            },
          },
          {
            path: ROUTE_NAME.BAO_CAO_THONG_KE.CHI_TIET,
            name: ROUTE_NAME.BAO_CAO_THONG_KE.CHI_TIET,
            component: () => import('@/modules/bao-cao-thong-ke/components/pages/ChiTiet.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.DANH_SACH_THONG_KE,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
]
