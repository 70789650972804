export const ROUTE_NAME = {
  QUAN_LY_QUY_TRINH: {
    NAME: '/quan-ly-quy-trinh/',
    QUAN_LY_LUONG_XU_LY: 'quan-ly-luong-xu-ly',
    CAU_HINH_QUY_TRINH: 'cau-hinh-quy-trinh',
    XEM: 'xem/:id',
    DANH_SACH: 'danh-sach',
  },
}

export const MENU_ROUTE_NAME = {
  QUAN_LY_QUY_TRINH: {
    NAME: 'Quản lý quy trình',
    QUAN_LY_LUONG_XU_LY: 'Quản lý luồng xử lý',
    CAU_HINH_QUY_TRINH: 'Cấu hình quy trình',
    XEM: 'Chi tiết quy trình',
    DANH_SACH: 'Danh sách',
  },
}
