import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/nhan-ket-qua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.HS_KET_QUA.NAME,
        name: ROUTE_NAME.HS_KET_QUA.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_KET_QUA.DS_NHAN_HS,
            name: ROUTE_NAME.HS_KET_QUA.DS_NHAN_HS,
            component: () => import('@/modules/nhan-ket-qua/components/pages/nhan-ket-qua/NhanKetQua.vue'),
            meta: {
              code: 'nhan-ket-qua-hs',
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.NHAN_KET_QUA_HO_SO.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.HS_KET_QUA.CT_NHAN_HS,
            name: ROUTE_NAME.HS_KET_QUA.CT_NHAN_HS,
            component: () => import('@/modules/nhan-ket-qua/components/pages/nhan-ket-qua/ChiTietNhanKetQua.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_KET_QUA,
                  to: ROUTE_NAME.HS_KET_QUA.DS_NHAN_HS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.NHAN_KET_QUA_HO_SO.CHI_TIET],
            },
          },
        ],
      },
    ],
  },
]
