<template>
  <div class="wrap-table">
    <vue-good-table
      ref="vueGoodTable"
      :columns="getColumns"
      :rows="rows"
      :line-numbers="true"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        Không có dữ liệu
      </div>
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.label =='action'">
          <feather-icon
            icon="PlusCircleIcon"
            size="20"
            class="icon-color"
            :disable="getDisabled"
            @click="addNewOption"
          />
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-for="(row, index) in fields.filter(field => field.field === props.column.field)"
          :key="props.column.field + `${index}`"
          class="text-wrap"
        >
          <b-form-input
            v-if="row.type === 'Text' && props.column.field === row.field"
            v-model="props.row[row.field]"
            :disabled="getDisabled"
            size="sm"
            @change="updatedRow(props.index, props.row)"
          />
          <b-form-input
            v-if="row.type === 'Number' && props.column.field === row.field"
            v-model="props.row[row.field]"
            :disabled="getDisabled"
            type="number"
            size="sm"
            @change="updatedRow(props.index, props.row)"
          />
          <b-form-datepicker
            v-if="row.type === 'Date time' && props.column.field === row.field"
            v-model="props.row[row.field]"
            :disabled="getDisabled"
            size="sm"
            @input="updatedRow(props.index, props.row)"
          />
        </div>
        <div
          v-if="props.column.field === 'action'"
          class="text-wrap"
        >
          <feather-icon
            icon="Trash2Icon"
            size="20"
            :disable="getDisabled"
            @click="deleteRow(props.index)"
          />
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormDatepicker,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'FormTable',
  components: {
    VueGoodTable,
    BFormInput,
    BFormDatepicker,
  },
  data() {
    return {
      columns: this.$parent.options
        ? [...this.$parent.options.map(col => ({
          label: col.columnName,
          field: col.attribute,
          dataType: col.dataType,
        })), {
          label: 'action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
        }]
        : [],
      rows: [],
      newItem: {},
      fields: this.$parent.options.map(col => ({ field: col.attribute, type: col.dataType })),
    }
  },
  computed: {
    getDisabled() {
      return this.$parent.$attrs.disabled || false
    },
    getColumns() {
      return this.$parent.$attrs.disabled ? this.columns.filter(item => item.field !== 'action') : this.columns
    },
  },
  mounted() {
    this.columns.forEach(col => {
      this.$set(this.newItem, `${col.field}`, null)
    })
  },
  methods: {
    addNewOption() {
      this.rows.unshift({ ...this.newItem, id: this.rows.length + 1 })
      this.$emit('control-value', this.rows.map(h => JSON.stringify(h)).toString())
    },
    deleteRow(index) {
      this.rows.splice(index, 1)
    },
    updatedRow(index, data) {
      this.rows[index] = data
    },
  },
}
</script>

<style scoped>

</style>
