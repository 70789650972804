import { ROUTE_NAME, MENU_ROUTE_NAME } from '@/modules/tra-ket-qua/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export default [
  {
    path: ROUTE_NAME.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.HS_GIAI_QUYET.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_GIAI_QUYET.DS_HS,
            name: ROUTE_NAME.HS_GIAI_QUYET.DS_HS,
            component: () => import('@/modules/tra-ket-qua/components/pages/giai-quyet/HoSoGiaiQuyet.vue'),
            meta: {
              code: ROUTE_NAME.HS_GIAI_QUYET.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_GIAI_QUYET,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_GIAI_QUYET.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.HS_GIAI_QUYET.DS,
            component: () => import('@/modules/tra-ket-qua/views/tra-ket-qua/HoSoKhongGiaiQuyet.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_GIAI_QUYET,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_GIAI_QUYET.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.HS_GIAI_QUYET.CT_HS,
            name: ROUTE_NAME.HS_GIAI_QUYET.CT_HS,
            component: () => import('@/modules/tra-ket-qua/components/pages/giai-quyet/ChiTietHoSoGiaiQuyet.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_GIAI_QUYET,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_GIAI_QUYET.CHI_TIET],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.HS_KHONG_GIAI_QUYET.NAME,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.HS_KHONG_GIAI_QUYET.DS,
            name: ROUTE_NAME.HS_KHONG_GIAI_QUYET.DS,
            component: () => import('@/modules/tra-ket-qua/views/tra-ket-qua/HoSoKhongGiaiQuyet.vue'),
            meta: {
              code: ROUTE_NAME.HS_KHONG_GIAI_QUYET.NAME,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_KHONG_GIAI_QUYET,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.HS_KHONG_GIAI_QUYET.DS,
            component: () => import('@/modules/tra-ket-qua/views/tra-ket-qua/HoSoKhongGiaiQuyet.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_KHONG_GIAI_QUYET,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.HS_KHONG_GIAI_QUYET.CT,
            name: ROUTE_NAME.HS_KHONG_GIAI_QUYET.CT,
            component: () => import('@/modules/tra-ket-qua/components/pages/khong-giai-quyet/ChiTietHoSoKhongGiaiQuyet.vue'),
            meta: {
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.HS_KHONG_GIAI_QUYET,
                  to: ROUTE_NAME.HS_KHONG_GIAI_QUYET.DS,
                },
                {
                  text: MENU_ROUTE_NAME.CHI_TIET_HO_SO,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.CHI_TIET],
            },
          },
        ],
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.NAME,
            },
            {
              text: MENU_ROUTE_NAME.HS_KHONG_GIAI_QUYET,
              active: true,
            },
          ],
        },
      },
    ],
  },
]
