<template>
  <div class="custom-control custom-switch">
    <input
      id="customSwitch1"
      v-model="value"
      type="checkbox"
      class="custom-control-input"
      :disabled="getDisabled"
      @change="onChange"
    >
    <label
      class="custom-control-label"
      for="customSwitch1"
    />
  </div>
</template>

<script>
export default {
  name: 'FormButtonSwitch',
  data() {
    return {
      value: false,
    }
  },
  computed: {
    getDisabled() {
      return this.$parent.$attrs.disabled || false
    },
  },
  methods: {
    onChange() {
      this.$emit('control-value', this.value)
    },
  },
}
</script>
