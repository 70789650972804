// eslint-disable-next-line import/named
import {
  ROUTE_NAME,
  MENU_ROUTE_NAME,
} from '@/modules/quan-ly-quy-trinh/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'

// eslint-disable-next-line import/prefer-default-export
export const router = [
  {
    path: ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
    name: ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
    component: {
      template: '<router-view/>',
    },
    children: [
      {
        path: ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
        name: ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
        component: {
          template: '<router-view/>',
        },
        children: [
          {
            path: ROUTE_NAME.QUAN_LY_QUY_TRINH.DANH_SACH,
            name: ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY + ROUTE_NAME.QUAN_LY_QUY_TRINH.DANH_SACH,
            component: () => import(
              '@/modules/quan-ly-quy-trinh/views/QuanLyLuongXuLy/TrangDanhSach.vue'
            ),
            meta: {
              pageTitle: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.QUY_TRINH_DONG.DANH_SACH],
            },
          },
          {
            path: '',
            name: ROUTE_NAME.QUAN_LY_QUY_TRINH.DANH_SACH,
            component: () => import(
              '@/modules/quan-ly-quy-trinh/views/QuanLyLuongXuLy/TrangDanhSach.vue'
            ),
            meta: {
              pageTitle: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
                  active: true,
                },
              ],
              permissionName: [PERMISSION_NAME.QUY_TRINH_DONG.DANH_SACH],
            },
          },
          {
            path: ROUTE_NAME.QUAN_LY_QUY_TRINH.XEM,
            name: ROUTE_NAME.QUAN_LY_QUY_TRINH.XEM,
            component: () => import(
              '@/modules/quan-ly-quy-trinh/views/QuanLyLuongXuLy/TrangChiTiet.vue'
            ),
            meta: {
              pageTitle: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
              breadcrumb: [
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
                },
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY,
                  to: `${ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME}${ROUTE_NAME.QUAN_LY_QUY_TRINH.QUAN_LY_LUONG_XU_LY}/${ROUTE_NAME.QUAN_LY_QUY_TRINH.DANH_SACH}`,
                },
                {
                  text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.XEM,
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: ROUTE_NAME.QUAN_LY_QUY_TRINH.CAU_HINH_QUY_TRINH,
        name: ROUTE_NAME.QUAN_LY_QUY_TRINH.CAU_HINH_QUY_TRINH,
        component: () => import('@/modules/quan-ly-quy-trinh/views/CauHinhQuyTrinh.vue'),
        meta: {
          breadcrumb: [
            {
              text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.NAME,
            },
            {
              text: MENU_ROUTE_NAME.QUAN_LY_QUY_TRINH.CAU_HINH_QUY_TRINH,
              active: true,
            },
          ],
          permissionName: [PERMISSION_NAME.CAU_HINH_QUY_TRINH_DONG.DANH_SACH],
        },
      },
    ],
  },
]
