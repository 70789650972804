<template>
  <div>
    <label
      v-if="tooltip"
      :for="context.id"
      v-html="tooltip"
    />
    <label
      v-else
      :for="context.id"
    >
      {{ context.label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: [String, Boolean],
      default: false,
    },
  },
}
</script>
