import {
  callApiDanhSachThoiGianLamViecTrongTuan,
  callApiThemMoiThoiGianLamViecTrongTuan,
  callApiSuaThoiGianLamViecTrongTuan,
  callApiXoaThoiGianLamViecTrongTuan,
} from '@/modules/danh-muc/api/danh-muc-dung-chung/thoi-gian-lam-viec-trong-tuan'

const LayDanhSachThoiGianLamViecTrongTuan = (context, payload) => new Promise((resolve, reject) => {
  callApiDanhSachThoiGianLamViecTrongTuan(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // context.commit('SET_DANH_SACH_LAM_VIEC_TRONG_TUAN', res.data)
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const ThemMoiThoiGianLamViecTrongTuan = (context, payload) => new Promise((resolve, reject) => {
  callApiThemMoiThoiGianLamViecTrongTuan(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const SuaThoiGianLamViecTrongTuan = (context, payload) => new Promise((resolve, reject) => {
  callApiSuaThoiGianLamViecTrongTuan(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

const XoaThoiGianLamViecTrongTuan = (context, payload) => new Promise((resolve, reject) => {
  callApiXoaThoiGianLamViecTrongTuan(payload)
    .then(res => {
      if (res.status === 200 && res.data) {
        // resolve(res)
      }
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  LayDanhSachThoiGianLamViecTrongTuan,
  ThemMoiThoiGianLamViecTrongTuan,
  SuaThoiGianLamViecTrongTuan,
  XoaThoiGianLamViecTrongTuan,
}
